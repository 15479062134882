.calendar {
  $self: &;
  display: flex;
  border-top: solid 1px map($color-blue, extraLight);
  border-bottom: solid 1px map($color-blue, extraLight);
  background-color: #F3F6FA;
  transition: background-color .3s ease;
  
  &:hover {
    background-color: rgba(map($color-blue, light), 0.4);
  }

  @include grid-media($grid-md) {
    display: block;
    
    h3 {
      font-size: rem(map($font-size-h3, \@s));
      line-height: map($line-height-h3, \@s);
    }

    p {
      font-size: rem(map($font-size-p, small));
      line-height: map($line-height-p, \@xs);
    }

    a {
      font-size: rem(map($font-size-a, extraSmall));
      line-height: map($line-height-a, extraSmall);
    }
  }

  & + .calendar {
    border-top: none;
    background: white;

    #{ $self}__event {
      transition: background-color .3s ease;
      
      &:hover {
        background-color: rgba(map($color-blue, light), 0.4);
      }
    }
  }

  // Elements
  &__title {
    position: relative;
    float: left;
    width: 30%;
    height: 100%;
    padding: 80px 40px;
    border-right: solid 1px map($color-blue, extraLight);
    
    @include grid-media($grid-md) {
      width: 50%;
      padding: 40px;
    }

    @include grid-media($grid-sm) {
      padding: 20px 20px 20px 40px;
    }

    @include grid-media($grid-xs) {
      padding: 20px 35px 20px 25px;
    }
  }

  &__link {
    @extend .icon-arrow-right;
    @extend .block;
    margin-top: 15px;

    &:nth-of-type(2) {
      margin-top: 20px;
    }
  }

  h3, p, a {
    color: map($color-blue, medium);
  }

  h3 {

    strong {
      color: map($color-blue, base);
    }
  }

  hr {
    margin: 8px 0;
    border-color: map($color-green, base);
  }

  &__events {
    float: left;
    width: 70%;
  
    @include grid-media($grid-md) {
      width: 50%;
      border-left: solid 1px map($color-blue, extraLight);
    }
  }
  
  &__event {
    position: relative;
    width: 17.5%;
    float: left;
    height: 100%;
    padding: 80px 40px;
    text-align: center;

    &:not(:nth-of-type(5)) {
      border-right: solid 1px map($color-blue, extraLight);
    }

    @include grid-media($grid-ml) {
      padding: 10px;
    }
  
    @include grid-media($grid-md) {
      width: 50%;
      padding: 40px ;
    }

    @include grid-media($grid-sm) {
      padding: 20px 40px 20px 20px;

      &:not(:nth-of-type(5)) {
        border-right: none;
      }
    }
  }
}