div.title {
  $self: &;
  padding: 60px 5% 80px 5%;

  h6 {
    color: map($color-blue, dark);

    @include grid-media($grid-md) {
      font-size: rem(map($font-size-h6, \@m));
      line-height: map($line-height-h6, \@m);
    }
  }

  h4 {
    @extend .tar;
    @extend .tac\@m;
    color: map($color-blue, base);

    & + p {
      margin-top: 10px;
    }

    @include grid-media($grid-md) {
      font-size: rem(map($font-size-h5, base));
      line-height: map($line-height-h5, base);
    }
  }

  h1 {
    color: map($color-blue, dark);

    strong {
      color: map($color-blue, base);
    }

    @include grid-media($grid-md) {
      font-size: rem(map($font-size-h1, \@m));
      line-height: map($line-height-h1, \@m);
    }
    @include grid-media($grid-xs) {
      font-size: rem(map($font-size-h1, \@s));
    }
  }

  p {
    @extend .tac\@m;
    color: map($color-blue, dark);
  }

  hr {
    margin: 60px 0;
    border-color: map($color-blue, extraLight);

    &.short {
      margin: 30px 0;
    }

    @include grid-media($grid-md) {
      margin: 30px 0;
    }
  }

  .summary {
    margin: 0;
  }

  @include grid-media($grid-md) {
    padding: 30px 5% 60px 5%;
  }

  // Elements
  &__content {
    position: relative;
    z-index: 1;

    p {
      padding: 0 6%;
    }
  }

  &__body {

    p {
      @extend .body;
      @extend .body-large;
      @extend .body\@m-medium;

      &:not(:first-child) {
        @extend .mt-5;
      }
    }
  }

  // Modifiers
  &--reverse {
    background-color: map($color-blue, mediumDark);

    h1, h4, p {
      color: map($color-white, base);
    }
    h4{
      strong {
        color: map($color-blue, sharpLight);
      }
    }

    h1 {
      @include grid-media($grid-xlm) {
        font-size: rem(map($font-size-h2, \@m));
        line-height: map($line-height-h2, base);
      }
      @include grid-media($grid-lg) {
        font-size: rem(map($font-size-h3, \@m));
        line-height: map($line-height-h3, base);
      }
      @include grid-media($grid-xs) {
        font-size: rem(map($font-size-h1, \@s));
      }

      strong {
        color: map($color-blue, extraLight);
      }
    }

    hr {
      border-color: map($color-green, base);
      border-width: 2px;
    }
  }

  &--tall {
    padding: 40px 5%;

    @include grid-media($grid-md) {
      padding: 60px 5% 70px 5%;
    }
  }

  &--short {
    padding: 0px 5% 60px 5%;

    @include grid-media($grid-md) {
      padding: 0px 5% 30px 5%;
    }
  }

  &--highlight {
    padding: 90px 5%;
    background-color: map($color-blue, ultraLight);

    p {

      &::first-letter {
        @extend .dropcaps;
      }
    }

    @include grid-media($grid-md) {
      padding: 30px;
    }
  }

  &--two-columns {

    h1 {
      @extend .tar;
      @extend .tac\@m;
      @extend .small\@m;
    }
  }

  &_helpful-links {
    color: map($color-white, base);
    font-family: $serif-book;
    font-size: rem(map($font-size-h1, extraSmall));
    line-height: map($line-height-h2, base);

    strong {
      color: map($color-blue, sharpLight);
    }

    @include grid-media($grid-md) {
      font-size: rem(map($font-size-h1, \@m));
      line-height: map($line-height-h1, \@m);
    }
    @include grid-media($grid-xs) {
      font-size: rem(map($font-size-h1, \@s));
    }
  }
}
