.list-wrapper {
 &.col-4 {
   @include grid-media($grid-md) {
     padding-top: 0;
   }
   padding-top: 3%;
 }
}

.list {
  $self: &;

  // Elements
  &__item {
    list-style: none;
    margin-bottom: 1.25em;

    a {
      line-height: 1.15em;
    }

    &:hover {
      
      a {
        color: map($color-green, base);

        span {
          text-decoration: underline;
          text-decoration-color: map($color-blue, superDark);
        }
      }
    }

    &.is-active {

      a {
        font-family: $sans-serif-bold;
        color: map($color-green, base);
      }
    }

    a {
      @extend .icon-arrow-right;
      color: map($color-blue, superDark);
      transition: color .3s ease;
    }

    @include grid-media($grid-md) {
      margin: 30px 0;

      a {
        line-height: map($line-height-a, extraSmall);
      }
    }
  }

  &__link {
    @extend .link;
    margin: 30px 0;
    color: map($color-blue, base);
    font-family: $sans-serif-bold;

    @include grid-media($grid-md) {
      margin: 60px 0;
    }
  }

  // Modifiers
  &--news {
    
    #{ $self }__item {

      a {
        @extend a\@m-extraSmall;
      }

      @include grid-media($grid-md) {
        margin: 5px 0;
      }
    }
  }

  &--primary {
    @extend .pr\@m-rs;
  }
}