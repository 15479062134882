.footer {

  // Elements
  &__content {
    @include grid-container;
    @extend .tar;
    @extend .tac\@ml;
    padding: 60px 80px;

    @include grid-media($grid-md) {
    	padding: 30px 40px;
    }
  }

  &__menu {
    text-transform: uppercase;
  }

  &__logo {
    @extend .inline-block;

    @include grid-media($grid-lg-min) {
      width: 100%;
      min-width: 225px;
    }

    @include grid-media($grid-ml) {
      width: auto;
    }
  }

  &__menu {

    @include grid-media($grid-ml) {
      margin-top: 30px;
    }
  }

  &__address {

    p {
      @extend .small;
      @extend .inline;
      @extend .block\@s;
      @extend .tlh2\@s;
    }

    a {
      @extend .small;
    }
  }

  &--content-box {
    width: auto;
    float: right;
  }
}
