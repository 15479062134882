#toolbar-bar {
  z-index: 3000;
}

* {
	box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  font-size: $font-size-base;
  //overflow-x: hidden;
  //overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

main {
  display: block; /* fix for IE 9/10/11 */
}

h1 {
  margin: 0;
  font-family: $serif-book;
  font-size: rem(map($font-size-h1, extraSmall));
  line-height: map($line-height-h1, base);

  strong {
    font-family: $serif-medium;
    color: map($color-blue, extraLight);
  }
}

h2 {
  margin: 0;
  font-family: $serif-book;
  font-size: rem(map($font-size-h2, base));
  line-height: map($line-height-h2, base);

  strong {
    font-family: $serif-medium;
    color: map($color-blue, extraLight);
  }
}

h3 {
  margin: 0;
  font-family: $serif-book;
  font-size: rem(map($font-size-h3, base));
  line-height: map($line-height-h3, base);

  strong {
    font-family: $serif-medium;
  }
}

h4 {
  margin: 0;
  font-family: $serif-book;
  font-size: rem(map($font-size-h4, extraSmall));
  line-height: map($line-height-h4, extraSmall);

  strong {
    font-family: $serif-extraBold;
  }
}

h5 {
  font-family: $serif-book;
  font-size: rem(map($font-size-h5, base));
  line-height: map($line-height-h5, base);

  strong {
    font-family: $serif-extraBold;
    color: map($color-blue, extraLight);
  }
}

h6 {
  font-family: $sans-serif-extraLight;
  font-size: rem(map($font-size-h6, base));
  line-height: map($line-height-h6, base);

  strong {
    font-family: $sans-serif-book;
  }
}

p {
  font-family: $sans-serif-book;
  font-size: rem(map($font-size-p, base));
  line-height: map($line-height-p, base);

  a {
    font-family: inherit;
    font-size: inherit;
    color: map($color-green, base);
  }
}

blockquote {
  font-family: $serif-mediumItalic;
  font-size: rem(map($font-size-blockquote, base));
  line-height: map($line-height-blockquote, base);
  color: map($color-blue, base);
}

figure {
  display: table;

  figcaption {
    color: map($color-white, base);
    font-family: $sans-serif-book;
    font-size: rem(map($font-size-p, mediumSmall));
    line-height: map($line-height-p, medium);

    em {
      font-family: $serif-bookItalic;
    }
  }
}

a {
  display: inline-block;
  font-family: $sans-serif-book;
  font-size: rem(map($font-size-a, base));
  line-height: map($line-height-a, base);
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

hr {
  border-color: map($color-blue, extraLight);
}

label, input, select, textarea, button {
  font-family: $sans-serif-book;
  font-size: rem(map($font-size-p, base));
  line-height: map($line-height-p, base);
}

button, input {
  cursor: pointer;
}

button:focus-visible, input:focus-visible, a:focus-visible {
  outline: 4px solid #A4C055;
  z-index: 1;
}

button:focus:not(:focus-visible), input:focus:not(:focus-visible), a:focus:not(:focus-visible) {
  outline: 0;
}

.js-focus-visible .focus-visible {
  outline: 4px solid #A4C055;
  z-index: 3001;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: 0;
}
