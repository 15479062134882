div.body {
  $self: &;

  p {
    @extend .body;
    @extend .body-medium;
  }
  
  // Column Modifiers
  &--three--columns {
    margin-left: -20px;
    margin-right: -20px;
    background-color: map($color-white, base);

    @include grid-media($grid-md) {
      margin-left: 0;
      margin-right: 0;
    }

    .col {

      &:not(:first-of-type) {
        border-left: solid 1px map($color-blue, dark);

        @include grid-media($grid-md) {
          border-right: none;
        }
      }

      &[data-top='0'] {

        .content {
          padding: 0 0 40px 0;
        }
      }

      &:not([data-top='0']) {

        .content {
          padding: 40px 0 0 0;
          border-top: solid 1px map($color-blue, sharpLight);
        }
      }
    }

    .content {
      margin: 0 40px;

      @include grid-media($grid-md) {
        margin: 0 5%;
      }
    }

    h4 {
      font-size: map($font-size-h4, medium);
      color: map($color-blue, dark);
      font-family: $serif-medium;
    }

    p {
      @extend .body\@m-base;
      margin-top: 60px;
      color: map($color-gray, dark);

      @include grid-media($grid-md) {
        margin-top: 5%;
      }

      strong {
        @extend p.body-large;
        @extend p.body.extraStrong;
        @extend p.body\@m-mediumLarge;
        color: map($color-blue, base);
      }
    }
  }
  &--two-columns {
    margin-left: -20px;
    margin-right: -20px;
    background-color: map($color-white, base);

    @include grid-media($grid-md) {
      margin-left: 0;
      margin-right: 0;
    }

    .col {

      &[data-left='0'] {
        border-right: solid 1px map($color-blue, sharpLight);

        @include grid-media($grid-md) {
          border-right: none;
        }
      }

      &[data-top='0'] {

        .content {
          padding: 0 0 40px 0;
        }
      }

      &:not([data-top='0']) {

        .content {
          padding: 40px 0 0 0;
          border-top: solid 1px map($color-blue, sharpLight);
        }
      }
    }

    .content {
      margin: 0 40px;

      @include grid-media($grid-md) {
        margin: 0 5%;
      }
    }

    h3 {
      @extend .mediumSmall;
      @extend h3\@m-small;
      color: map($color-blue, dark);
    }

    p {
      @extend .body\@m-base;
      margin-top: 60px;
      color: map($color-blue, dark);

      @include grid-media($grid-md) {
        margin-top: 5%;
      }

      strong {
        @extend p.body-large;
        @extend p.body.extraStrong;
        @extend p.body\@m-mediumLarge;
      }
    }
  }
}