.panel {
  $self: &;
  min-height: 400px;
  position: relative;

  .image-background {
    position: absolute;
  }

  @include grid-media($grid-lg-min) {

    h3 {
      font-size: rem(map($font-size-h3, \@s));
      line-height: map($line-height-h3, \@s);
    }
  }

  @include grid-media($grid-lg) {

    h2 {
      font-size: rem(map($font-size-h2, \@m));

      & + span {
        font-size: rem(map($font-size-a, extraSmall));
      }
    }
  }

  @include grid-media($grid-md) {

    h1 {
      font-size: rem(map($font-size-h2, \@m));
    }

    h3 {
      font-size: rem(map($font-size-h3, \@s));
      line-height: map($line-height-h3, \@s);
    }

    h5 {
      font-size: rem(map($font-size-h5, \@xs));
      line-height: map($line-height-h5, \@xs);

      strong {
        margin-top: 0;
      }
    }
  }

  &:hover {

    #{ $self }__content {

      h1, h2, h3 {

        strong {
          color: map($color-green, base);
        }
      }
    }
  }

  // Blocks
  &-fact {
    $self: &;

    .overlay {

      .overlay__content {
        padding: 0 30px;
      }
    }
  }

  &-overlay, .overlay {
    @extend .overlay;

    &__content {
      position: absolute;
      padding: 15px;

      h2, h3, h5 {
        color: map($color-white, base);
      }

      h2 {
        font-size: map($font-size-h3, base);
        line-height: 1.15;
      }
    }
  }

  &__group {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  // Elements
  &__link {
    margin: 15px 0 0 0;
    line-height: 5;
    text-transform: uppercase;
    padding-left: 1em;

    &:first-of-type {
      padding-left: 0;
    }

    &::after {
      top: 3px;
    }

    @include grid-media($grid-md) {
      font-size: map($font-size-p, small)
    }
  }

  // Modifiers
  &--featured {
    min-height: 500px;

    &:focus-within {
      border: 4px solid #A4C055;
      z-index: 100;
    }

    @include grid-media($grid-md) {
      min-height: 350px;
    }

    #{ $self }__content {
      padding: 0 0 40px 30px;

      h1, h2, h3 {

        strong {
          transition: color .3s ease;
        }
      }
    }
  }

  &--wide {

    .image-background {
      background-position: top center;
    }

    #{ $self }__content {
      padding: 0 150px;

      @include grid-media($grid-md) {
        padding: 0 0 40px 30px;
        top: auto;
        bottom: 0;
        transform: none;
      }
    }
  }

  &--tall {
    min-height: 800px;

    h1 {
      color: map($color-blue, ultraLight);

      strong {
        color: map($color-white, base);
      }
    }

    @include grid-media($grid-md) {
      min-height: 400px;
    }
  }

  &--thumb {
    min-height: 350px;
    width: 100%;

    &:hover {

      h5 {

        strong {
          color: map($color-green, base);
        }
      }
    }

    #{ $self }-overlay__content {
      padding: 0 20px;

      h5 {

        strong {
          display: inline-block;
          margin-top: 10px;
          transition: color .3s ease;
        }
      }
    }

    @include grid-media($grid-md) {
      min-height: 250px;
    }

    @include grid-media($grid-sm) {
      min-height: 150px;
    }
  }

  &-wrapper--two-columns {
    border-top: solid 2px map($color-blue, medium);
  }

  &--two-columns {
    border-bottom: solid 2px map($color-blue, sharpLight);

    .panel {
      min-height: 360px;

      @include grid-media($grid-md) {
        min-height: 240px;
      }

      &:last-of-type {
        background-color: map($color-blue, medium);
      }

      .panel__title {

        h4 {
          @extend h4.medium;
          @extend h4.wide;
          @extend h4\@m-extraSmall;

          @include grid-media($grid-xs) {
            letter-spacing: 5px;
          }
        }
      }

      .panel__body {
        @extend .pav;
        @extend .pr\@m;
        padding: 0 150px 0 50px;

        @include grid-media($grid-md) {
          padding: 0 5%;
        }

        p {
          @extend p.body;
          @extend p.summary-tall;
          @extend p.body\@m-mediumSmall;
          color: map($color-white, base);

          @include grid-media($grid-lg) {
            font-size: map($font-size-p, mediumSmall);
          }

          @include grid-media($grid-ml) {
            font-size: map($font-size-p, small);
          }

          @include grid-media($grid-md) {
            font-size: map($font-size-p, base);
          }

          @include grid-media($grid-sm) {
            font-size: map($font-size-p, mediumSmall);
          }

        }
      }

      .image-background {
        background-position: top;
      }
    }
  }
}
