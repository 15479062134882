.callout {
  $self: &;

  // Modifieres
  &--inset {
    position: relative;
    background-color: map($color-blue, base);

    #{ $self }__content {
      padding: 100px 0;
      @extend .p\@m-5;

      h3, p {
        color: map($color-white, base);
      }

      h3 {
        @extend h3\@m-small;
      }

      p {
        @extend .body;
        @extend .body-mediumExtraLarge;
        @extend .body\@m-base;
        margin: 30px 0;

        &:last-of-type {
          margin: 30px 0 0 0;
        }
      }
    }
  }

  // Elements
  &__title {
    @extend .title;
    @extend .title--short;
  }

  &__content {
    padding: 60px 0;

    @include grid-media($grid-md) {
      padding: 0;
    }
  }

  &__image {
    padding-right: 20px;

    @include grid-media($grid-md) {
      padding-right: 0;
    }
  }

  &__body {

    h4 {
      color: map($color-blue, dark);

      @include grid-media($grid-md) {
        font-size: rem(map($font-size-h4, \@s));
        line-height: map($line-height-h4, \@s);
      }
    }

    p {
      margin-top: 60px;
    }

    a {
      margin-top: 30px;

      &::after {
        left: 15px;
      }

      strong {
        color: map($color-blue, base);
      }

      @include grid-media($grid-md) {
        font-size: rem(map($font-size-h5, \@s));
        line-height: map($line-height-h5, \@s);

        &::after {
          top: 2px;
          left: 10px;
        }
      }
    }

    @include grid-media($grid-md) {
      margin-top: 30px;
      padding: 0 5%;

      p {
        margin-top: 30px;
      }
    }
  }
}