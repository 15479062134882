.announcement {

  h4 {
    @extend h4.title;
  }

  p {
    @extend p.body;
    @extend p.body-mediumLarge;
    margin: 2em 0;

    &:last-of-type {
      margin: 2em 0 0 0;
    }

    a {
      @extend a;
      @extend .link;
      color: map($color-blue, superDark);
      text-transform: uppercase;
    }
  }
}