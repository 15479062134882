.header {
  position: sticky;
  top: 0;
  width: 100%;
  background: white;
  z-index: 2000;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

  // Elements
  &__content {
    @include grid-container;
    padding: 60px 80px;
    
    @include grid-media($grid-ml) {
    	padding: 30px 40px;
    }

    @include grid-media($grid-md) {
    	padding: 30px 20px;
    }
  }

  .logo-container{
  	@include grid-media($grid-md) {
  		width:80%;
  	}
  }
}

.sticky-top-ie{
  position:fixed;
  width:100%;
  z-index:99;
  top:0px;
}

.dialog-off-canvas-main-canvas{
  overflow:visible;
}