nav.breadcrumbs {
  padding: 8px 60px;
  background-color: map($color-blue, mediumDark);
  text-align: right;

  @include grid-media($grid-sm) {
    padding: 8px 10px;
  }

  a, span {
    @extend .small;
    font-family: $sans-serif-bold;
    color: map($color-white, base);
    text-transform: uppercase;
  }

  span {
    position: relative;
    top: 1px;
    margin: 0 5px;

    &:not(.separator):last-of-type {
      @extend a;
      margin: 0;
      top: 0;
      cursor: initial;
    }
  }
}