.alert {
  padding: 1.5em;
  text-align: center;
  font-weight: 600;
  border: 1px dashed map($color-black, base);

  * {
    color: map($color-white, base);
  }

  &-primary {
    background-color: map($bg-color-alert, primary);
  }

  &-secondary {
    background-color: map($bg-color-alert, secondary);
   
    * {
      color: map($color-alert, secondary);
    }
  }

  &-success {
    background-color: map($bg-color-alert, success);
  }

  &-danger {
    background-color: map($bg-color-alert, danger);
  }

  &-warning {
    background-color: map($bg-color-alert, warning);

    * {
      color: map($color-alert, warning);
    }
  }

  &-info {
    background-color: map($bg-color-alert, info);
  }

  &-light {
    background-color: map($bg-color-alert, light);

    * {
      color: map($color-alert, light);
    }
  }

  &-dark {
    background-color: map($bg-color-alert, dark);
  }
}