@media print {
	.article__share-buttons, .article__breadcrumbs, .breadcrumbs, iframe, #helpful-links, footer, #block-menushare, button, .article__share, #block-views-block-news-related-news {
		display: none;
	}
	.logo-container {
		float: none;
		margin: auto;
	}
	.header__content {
		padding: 20px 0 0 !important;
	}
	figure {
		max-width: 25%;
		float: right;
	}
	figcaption {
		font-size: .75rem !important;
	}
	a[href]:after {
		content: none !important;
	}
	blockquote {
		p {
			font-size: 1rem !important;
		}
	}
	.article__image {
		width: 35% !important;
		padding-right: 5% !important;
	}
	.article__author {
		width: 60% !important;
	}
	.article__author-hours, .article__author-website {
		width: 50% !important;
		float: left;
	}
	.article__author-name {
		font-size: 2rem !important;
		line-height: 1 !important;
		padding: 0 !important;
		margin: 0 !important;
	}
	.article__author-role {
		font-size: .75rem !important;
		margin: 10px !important;
	}
	.article__author-contact, .article__author-address p, .article__author-address a, .article__author-website, .article__author-website p, .article__author-website a, .article__author-hours, .article__author-hours p{
		font-size: .5rem !important;
	}
	hr {
		margin: .25em;
	}
	.menu--header {
		display: none !important;
	}
	.menu--quick {
		display: none !important;
	}
}