@import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond:400,700|Lato:300,900');

@font-face {
  font-family: TrajanProRegular;
  src: url('https://staticws.b-cdn.net/assets/fonts/TrajanPro/TrajanPro-Regular.otf') format("opentype");
  src: url('https://staticws.b-cdn.net/assets/fonts/TrajanPro/TrajanPro-Regular.woff2') format('woff2'), /* Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/TrajanPro/TrajanPro-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/TrajanPro/TrajanPro-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: CopernicusBold;
  src: url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Bold/Bold.otf') format("opentype");
  src: url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Bold/Bold.woff2') format('woff2'), /* Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Bold/Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Bold/Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: CopernicusExtraBold;
  src: url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Extrabold/Extrabold.otf') format("opentype");
  src: url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Extrabold/Extrabold.woff2') format('woff2'), /* Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Extrabold/Extrabold.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Extrabold/Extrabold.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: CopernicusHeavyItalic;
  src: url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Heavy Italic/HeavyItalic.otf') format("opentype");
  src: url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Heavy Italic/HeavyItalic.woff2') format('woff2'), /* Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Heavy Italic/HeavyItalic.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Heavy Italic/HeavyItalic.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: CopernicusMedium;
  src: url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Medium/Medium.otf') format("opentype");
  src: url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Medium/Medium.woff2') format('woff2'), /* Pretty Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Medium/Medium.woff') format('woff'), /* Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Medium/Medium.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: CopernicusMediumItalic;
  src: url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Medium Italic/MediumItalic.otf') format("opentype");
  src: url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Medium Italic/MediumItalic.woff2') format('woff2'), /* Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Medium Italic/MediumItalic.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Medium Italic/MediumItalic.ttf')  format('truetype'); /* Safari, Android, iOS */
       }

@font-face {
  font-family: CopernicusBook;
  src: url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Book/Book.otf') format("opentype");
  src: url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Book/Book.woff2') format('woff2'), /* Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Book/Book.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Book/Book.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: CopernicusBookItalic;
  src: url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Book Italic/BookItalic.otf') format("opentype");
  src: url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Book Italic/BookItalic.woff2') format('woff2'), /* Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Book Italic/BookItalic.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Copernicus/Copernicus Book Italic/BookItalic.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: MalloryBook;
  src: url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Book/Book.otf') format("opentype");
  src: url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Book/Book.woff2') format('woff2'), /* Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Book/Book.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Book/Book.ttf')  format('truetype'); /* Safari, Android, iOS */
       }

@font-face {
  font-family: MalloryLight;
  src: url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Light/Light.otf') format("opentype");
  src: url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Light/Light.woff2') format('woff2'), /* Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Light/Light.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Light/Light.ttf')  format('truetype'); /* Safari, Android, iOS */
       }

@font-face {
  font-family: MalloryExtraLight;
  src: url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory ExtraLight/ExtraLight.otf') format("opentype");
  src: url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory ExtraLight/ExtraLight.woff2') format('woff2'), /* Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory ExtraLight/ExtraLight.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory ExtraLight/ExtraLight.ttf')  format('truetype'); /* Safari, Android, iOS */
       }

@font-face {
  font-family: MalloryBold;
  src: url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Bold/Bold.otf') format("opentype");
  src: url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Bold/Bold.woff2') format('woff2'), /* Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Bold/Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Bold/Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
       }

@font-face {
  font-family: MalloryBlack;
  src: url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Black/Black.otf') format("opentype");
  src: url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Black/Black.woff2') format('woff2'), /* Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Black/Black.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Mallory/Mallory Black/Black.ttf')  format('truetype'); /* Safari, Android, iOS */
       }

@font-face {
  font-family: Icons;
  src: url('https://staticws.b-cdn.net/assets/fonts/Icons/rice.woff2') format('woff2'), /* Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Icons/rice.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://staticws.b-cdn.net/assets/fonts/Icons/rice.ttf')  format('truetype'); /* Safari, Android, iOS */
       }
