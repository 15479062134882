.card {
  $self: &;
  display: block;

  @include grid-media($grid-md) {

    h4 {
      font-size: rem(map($font-size-h4, \@s));
      line-height: map($line-height-h4, \@s);
    }

    .button {
      font-size: rem(map($font-size-button, \@m));
    }
  }

  &:hover {

    #{ $self }__title {

      a {
        color: map($color-green, base);
      }
    }

    #{ $self }__image {

      h4 {

        strong {
          color: map($color-blue, superLight);
        }
      }
    }
  }

  // Blocks
  &-overlay {
    @extend .overlay;

    &__content {
      position: absolute;
      padding: 0;

      p, h5 {
        color: map($color-white, base);
      }

      h4, a, span {
        color: map($color-white, base);
      }
      h4 {
        @include grid-media($grid-sm) {
          max-width: 125px;
        }
      }
    }
  }

  // Elements
  &__image {
    position: relative;
    min-height: 350px;

    @include grid-media($grid-md) {
      min-height: 250px;
    }

    &--wide {

      h4 {
        float: left;
        width: 60%;
        text-align: center;
        @extend .tal\@m;
      }

      #{ $self }__icon {
        position: absolute;
        top: 50%;
        right: 60px;
        transform: translateY(-50%);
        width: 40%;
        text-align: center;
      }
    }

    h4 {

      strong {
        color: map($color-white, base);
        transition: color .3s ease;
      }
    }

    a, i, span {
      display: block;
      margin: 0 auto;
    }
  }

  &__summary {

    p {
      color: map($color-gray, dark);
      margin: 12px 0 0 0;
      line-height: 1.5;

      a {
        display: inline;
        color: map($color-blue, dark);
      }
    }
  }

  // Modifiers
  &--bio {
    @include grid-container;
    display: flex;
    //align-items: center;

    &:hover {

      #{ $self }__image {

        img {
          border-color: map($color-green, base);
        }
      }

      #{ $self }__title {

        a {
          color: map($color-green, base);
        }
      }
    }

    #{ $self }__image {
      float: left;
      min-height: 0;

      img {
        border: solid 8px transparent;
        transition: border-color .3s ease;
      }
    }

    #{ $self }__title {
      float: left;
      padding: 0 0 0 20px;

      @include grid-media($grid-md) {
        padding: 0 20px 0 20px;
      }

      a {
        @extend .link__text;
        transition: color .3s ease;

        &::after {
          top: 2px;
          left: 8px;
        }
      }

      p {
        margin-top: 8px;
        font-size: map($font-size-p, mediumSmall);
      }
    }
  }

  &--stacked {
    vertical-align: top;

    &:hover {

      #{ $self }__image {

        &::after {
          opacity: 1;
        }
      }

      #{ $self }__title {
        color: map($color-green, base);
      }
    }

    &:last-of-type, &:nth-of-type(4) {

      #{ $self }__content {
        border-right: none !important;
      }
    }

    #{ $self }__image {
      overflow: hidden;

      .image-background {
        position: absolute;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        border: solid 8px map($color-green, base);
        transition: opacity .3s ease;
        opacity: 0;
        z-index: 1;
      }
    }

    #{ $self }__content {
      @extend .border-r;
      padding: 0 20px;

      @include grid-media($grid-md) {
        border-right: none;
      }
    }

    #{ $self }-overlay__content {
      padding: 20px;
    }

    #{ $self }__info {
      @extend p.body\@m-small;

      span {
        display: inline-block;
      }
    }

    #{ $self }__title {
      @extend .h5;
      @extend .h5.strong;
      transition: color .3s ease;
      font-family: $serif-bold;
      font-size: rem(map($font-size-a, medium));
      line-height: 1.25;

      @include grid-media($grid-xs) {
        font-size: 1.25em;
      }
    }

    #{ $self }__summary {

      p {
        @extend p.body\@m-small;
        @extend .tlh2\@m;
      }
    }

    a {
      position: relative;
      display: block;
      margin: 0 auto;
      z-index: 999;

      &#{ $self }__author {

        display: inline-block;
        color: map($color-blue, superLight);
        transition: color .3s ease;

        &:hover {
          color: map($color-white, base);
        }
      }

      &.h5 {
        @extend .icon-arrow-right;

        &::after {
          top: 2px;
        }
      }
    }
  }

  &--panel {

    #{ $self }__content {
      padding: 0 20px;

      @include grid-media($grid-md) {
        padding: 0 10px;
      }
    }

    #{ $self }__image {

      @include grid-media($grid-md) {
        min-height: 160px;

        a {
          width: auto;
          right: 40px;
        }
      }
    }

    #{ $self }-overlay__content {
      padding: 20px;
    }
  }
}
