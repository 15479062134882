/*
==============
Position
==============
*/
.p {

  &a {
    position: absolute !important;

    &c {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &v {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &h {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &r {
    position: relative;

    &\@m {
      @include grid-media($grid-md) {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
      }
    }
  }
}

/*
==============
Display
==============
*/
.flex {
  display: flex;

  &-1 {
    flex: 1;
  }

  &-dir {

    &-row {
      flex-direction: row;
    }

    &-col {
      flex-direction: column;
    }
  }

  &-c {
    align-items: center;
  }

  &-stretch {
    align-items: stretch;
  }

  // Breakpoint Medium
  &\@m {
    @include grid-media($grid-md) {
      display: flex !important;

      &-order {
        flex-wrap: wrap;

        &-1 {
          order: 1;
        }

        &-2 {
          order: 2;
        }

        &-3 {
          order: 3;
        }
      }
    }
  }
}

.block {
  &-wrapper {
    position: sticky;
  }
  display: block;

  // Breakpoint Medium Large
  &\@ml {
    @include grid-media($grid-ml) {
      display: block !important;
    }
  }

  // Breakpoint Medium
  &\@m {
    @include grid-media($grid-md) {
      display: block !important;
    }
  }

  // Breakpoint Small
  &\@s {
    @include grid-media($grid-sm) {
      display: block !important;
    }
  }

  // Breakpoint Extra Small
  &\@xs {
    @include grid-media($grid-xs) {
      display: block !important;
    }
  }
}

.inline {
  display: inline;

  // Breakpoint Large
  &\@l {
    @include grid-media($grid-lg) {
      display: inline !important;
    }
  }

  // Breakpoint Medium
  &\@m {
    @include grid-media($grid-md) {
      display: inline !important;
    }
  }

  // Breakpoint Small
  &\@s {
    @include grid-media($grid-sm) {
      display: inline !important;
    }
  }
}

.inline-block {
  display: inline-block;
}

.arrow {
  position: relative;;

  &::after {
    position: absolute;
    top: -4px;
    content: "\f104";
    font: normal normal normal 14px/1 Font Awesome\ 6 Pro;
    font-size: map($font-size-p, large);
    color: map($color-white, base);
    display: inline-block;
    transition: all .3s ease;
    transform-origin: center center;
    margin-left: .5em;
    @include grid-media($grid-md) {
      color: map($color-blue, base);
    }
  }
}

.rotate {
  color: map($color-blue, extraLight) !important;

  &::after {
    transform: rotate(-90deg);
    color: map($color-blue, extraLight);
  }

  @include grid-media($grid-md) {
    color: map($color-blue, base) !important;

    &::after {
      color: map($color-blue, base);
    }
  }

  li:first-child {
    border-top: .5px solid rgba(255,255,255,.5);
    margin-top: .5em;

    @include grid-media($grid-md) {
      border-top: .5px solid map($color-blue, light);
    }
  }
}
.remove-bg {
  background: none !important;
}

.visually-hidden {
  color: #5a5a5a;
}
/*
==============
Hide
==============
*/
.hideim {
  display: none !important;
}
.hide {
  display: none;

  // Breakpoint Medium
  &\@m {
    @include grid-media($grid-md) {
      display: none !important;
    }
  }

  // Breakpoint Small
  &\@s {
    @include grid-media($grid-sm) {
      display: none !important;
    }
  }

  // Breakpoint Extra Small
  &\@xs {
    @include grid-media($grid-xs) {
      display: none !important;
    }
  }
}

.fade {
  opacity: 1 !important;
  z-index: 3000;
  pointer-events: auto !important;
}

.stick {
  position: fixed !important;
  z-index: 4000;
  right: 147px;
  top: 60px;

  @include grid-media($grid-ml) {
    right: 56px;
    top: 30px;
  }
}

/*
==============
Floats
==============
*/
.f {
  // Left
  &l {
    float: left;
  }

  // Right
  &r {
    float: right;
  }

  // Breakpoint Medium
  &\@m {
    @include grid-media($grid-md) {
      // Reset
      &-rs {
        float: none;
      }
    }
  }

  // Breakpoint Small
  &\@s {
    @include grid-media($grid-sm) {
      // Reset
      &-rs {
        float: none;
      }
    }
  }
}

/*
==============
Image
==============
*/
.image {

  &-c {
    margin: 0 auto;
  }

  &-max {
    max-width: 100%;
    height: auto;
  }

  &-responsive {
    width: 100%;
    height: auto;
  }

  &-background {
    position: relative;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &-zoom {
    position: relative;
    overflow: hidden;

    &:hover {

      .image-background {
        transform: scale(1.05);
      }
    }

    .image-background {
      position: absolute;
      transition: transform .5s ease;
    }
  }
}

.dual-img {
  transform: translateY(-50%);
}

/*
==============
Video
==============
*/
.video {

  &-responsive {
    width: 100%;
    height: auto;
  }
}

/*
==============
Background
==============
*/
.background {

  &-r {
    background-position: right;
  }
}

/*
==============
Rules
==============
*/
.rule {
  width: 100%;
  margin: 0;
}

.col-separator {
  >.grid-mw--1080>div {
    display: flex;
    @include grid-media($grid-sm){
      display: block;
    }
    >.col {
      flex: 1;
      @include grid-media($grid-sm) {
        margin: -20px 0;
      }
    }
    >.col:not(:first-of-type) {
      border-left: 1px solid black;
      @include grid-media($grid-sm){
        border: none;
      }

    }
  }
}
/*
==============
Misc
==============
*/
.lock {
  overflow: hidden;
  height: 100vh;
  position: fixed;
  width: calc(100vw - 15px);
}
