.grid--tour {

  &:not(:last-of-type) {
    @extend .border-b;
    @extend .border\@m-rs;
  }
  
  &:nth-of-type(odd) {
    @extend .grid;
    @extend .grid\@m;
    @extend .grid-vg;

    .card--tour {

      &:not(:last-of-type) {
        @extend .col-3;
        @extend .col\@m-4;

        .card__icon {

          span {
            display: none;
          }
        }
      }

      &:last-of-type {
        @extend .col-6;
        @extend .col\@m-8;

        .card__image {
          @extend .card__image--wide;

          span {
            @extend p.body\@m-small;
          }
        }
      }
    }
  }

  &:nth-of-type(even) {
    @extend .grid;
    @extend .grid\@m;
    @extend .grid-vg;
    @extend .flex\@m; 
    @extend .flex\@m-order;

    .card--tour {
      
      &:not(:first-of-type) {
        @extend .col-3;
        @extend .col\@m-4;

        .card__icon {
          
          span {
            display: none;
          }
        }
      }

      &:first-of-type {
        @extend .col-6;
        @extend .col\@m-8;
        @extend .flex\@m-order-3;

        .card__image {
          @extend .card__image--wide;
        }
      }

      &:nth-of-type(2) {
        @extend .flex\@m-order-2;
      }

      &::last-of-type {
        @extend .flex\@m-order-1;
      }
    }
  }
  
  .card--tour {

    &:not(:last-of-type) {

      .card__content {
        @extend .border-r;
        @extend .border\@m-r-rs;
      }
    }
  }
}