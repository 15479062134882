.hero {

  // Elements
  &__image {
    min-height: 500px;

    @include grid-media($grid-md) {
      min-height: 300px;
    }
  }

  &__title {
    
    p {
      margin-top: 48px;
    }

    p + h1 {
      margin-top: 24px;
    }

    h1 {
      @include grid-media($grid-md) {
        font-size: rem(map($font-size-h1, \@m));
        line-height: map($line-height-h1, \@m);
      }
      @include grid-media($grid-xs) {
        font-size: rem(map($font-size-h1, \@s));
      }
    }
  }

  &__breadcrumbs {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;

    @include grid-media($grid-md) {
      display: none;
    }
  }

  &__footer {
    padding: 40px 5%;
    text-align: center;
    color: map($color-blue, dark);
    background-color: map($color-gray, ultraLight);

    p {
      @extend .mh-10;
      @extend .mh\@m-5;
      @extend .body;
      @extend .body-large;
      @extend .body\@m-medium;
    }

    h4 {
      @extend .mb-4;
      @extend h4.small;
      font-family: $serif-medium;
      text-align: center !important;

      &>strong {
        color: map($color-blue, base);
      }
    }

    h3 {
      @extend h3\@m-small;
    }

    h2 {
      border-bottom: 1px solid map($color-blue, light);
      padding-bottom: .5em;
      margin-bottom: -.75em;
      strong {
        color: map($color-blue, base);
      }
    }
  }

  //Modified
  &__image-mid-wrapper {
    
    .hero__image-mid {
      width: 100%;
      min-height: 950px;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;

      @include grid-media($grid-xlm) {
        min-height: 900px;
      }

      @include grid-media($grid-lg) {
        min-height: 800px;
      }

      @include grid-media($grid-ml) {
        min-height: 700px;
      }

      @include grid-media($grid-md) {
        min-height: 600px;
      }

      @include grid-media($grid-ms) {
        min-height: 400px;
      }

      @include grid-media($grid-sm) {
        min-height: 300px;
      }
  
      .title-wrapper {
        @extend.pah;
        @extend.tac;
        bottom: 0;
        @extend.pv-3;
        color: map($color-white, base);

        h6 {
          text-transform: uppercase;

          @include grid-media($grid-md) {
            font-size: 1rem;
          }
          @include grid-media($grid-ms) {
            font-size: .75rem;
          }
        }

        h2 {
          @include grid-media($grid-md) {
            font-size: 3rem;
          }
          @include grid-media($grid-ms) {
            font-size: 2rem;
          }
          strong {
            color: map($color-blue, light);
          }
        }
      

      }
    }
    
    .caption-wrapper {
      background-color: map($color-blue, mediumDark);
      bottom: 0;

      .caption {
        text-align: center;
        padding: 60px 0;
        font-size: 1.5rem;
        font-family: $serif-bold;
        color: map($color-white, base);

        @include grid-media($grid-md) {
          font-size: .75rem;
        }
        @include grid-media($grid-ms) {
          padding: 30px 50px;
        }
      }
    }
  }
}