.link {
  $self: &;
  position: relative;
  @extend .icon-arrow-right;

  // Blocks
  &-card {
    position: relative;
    min-height: 175px;
    width: 100%;
    text-align: center;

    #{ $self }__content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;

      i {
        position: absolute;
        top: 50%;
        right: 36px;
        transform: translateY(-50%);
      }
    }

    &:hover {

      h5, h5 strong {
        color: map($color-green, base) !important;
      }
    }

    h5, h5 strong {
      transition: color .3s ease;
    }

    @include grid-media($grid-md) {
      min-height: 120px;
    }
  }

  &__cta {
    background-color: map($color-blue, dark);
    color: map($color-white, base);
    font-weight: bold;
    padding: 1em 2.25em;
    text-decoration: none;
    font-size: rem(map($font-size-a, base));
    display: inline-block !important;

    @include grid-media($grid-sm) {
      padding: 1em 1.3em;
      font-size: rem(map($font-size-a, small));
      margin: 2em 0 3em 0;
    }
  }

  // Elements
  &__content {
    flex: 1;
    padding: 0 35px;
  }

  &__text {
    padding: 4px 0;
    border-bottom: solid 1px map($color-green, base);
  }

  // Modifiers
  &--small {
    color: map($color-blue, base);
  }

  &--inline {
    background-color: map($color-blue, base);
    border-top: solid 1px map($color-white, base);
    border-right: solid 1px map($color-white, base);
    border-bottom: solid 1px map($color-white, base);

    &:last-of-type {
      border-right: none;
    }

    h5 strong, p {
      color: map($color-white, base);
    }

    h5 {
      position: relative;

      &::after {
        position: absolute;
        right: -15px;
        top: 50%;
        transform: translateY(-50%);

        @include grid-media($grid-md) {
          position: relative;
          right: 0;
        }
      }
    }

    @include grid-media($grid-lg) {

      h5 {
        font-size: rem(map($font-size-h5, small));
        line-height: map($line-height-h5, small);
      }
    }

    @include grid-media($grid-md) {
      border-right: none;
      border-top: none;
    }
  }

  &--inline-block {
    background-color: map($color-blue, mediumDark);

    &:hover {

      .link__title {
        color: map($color-green, base) !important;
      }
    }

    .link__title {
      @extend .icon-arrow-right;
      position: relative;
      color: map($color-white, base);
      padding: 50px;
      transition: color .3s ease;
    }
  }

  &--block {
    background-color: map($color-blue, mediumDark);

    #{ $self }__title {
      @extend .icon-arrow-right;
      position: relative;
      color: map($color-white, base);

      &::after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      strong {
        display: block;
        font-family: $serif-extraBold;
        color: map($color-blue, superLight);
      }

      @include grid-media($grid-ml) {

        &::after {
          right: -15px;
        }
      }

      @include grid-media($grid-md) {
        font-size: rem(map($font-size-h5, \@xs));
        line-height: map($line-height-h5, \@xs);

        &::after {
          right: 0;
        }
      }

      @include grid-media($grid-sm) {

        &::after {
          right: -13px;
        }
      }
    }
  }
}
.invert-link {
  margin-top: -8%;
  .link--inline-block {
    background-color: map($color-blue, extraLight);

    &:hover {

      .link__title {
        color: map($color-green, base) !important;
      }
    }

    .link__title {
      @extend .icon-arrow-right;
      position: relative;
      color: map($color-blue, mediumDark);
      padding: 50px;
      transition: color .3s ease;
    }
  }
}

.skip-to-content:focus, .skip-to-content:active, .skip-to-content:hover  {
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  z-index: 3001;
  border: 0;
  margin: 0;
  font-size: rem(map($font-size-a, medium));
  line-height: map($line-height-a, superSmall);
  background-color: map($color-blue, dark);
  color: map($color-white, base);
  clip: inherit;
  transition: none;
}

