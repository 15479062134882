@include grid-media($grid-md) {

  .iso-layout {
    height: 100% !important;
  }

  .iso-item {
    position: relative !important;
    left: auto !important;
    top: auto !important;
  }
}