/*
==============
Fonts
==============
*/
$sans-serif-book: 'MalloryBook', sans-serif;
$sans-serif-light: 'MalloryLight', sans-serif;
$sans-serif-extraLight: 'MalloryExtraLight', sans-serif;
$sans-serif-bold: 'MalloryBold', sans-serif;
$sans-serif-black: 'MalloryBlack', sans-serif;

$serif-title: 'TrajanProRegular', serif;
$serif-bold: 'CopernicusBold', serif;
$serif-extraBold: 'CopernicusextraBold', serif;
$serif-medium: 'CopernicusMedium', serif;
$serif-mediumItalic: 'CopernicusMediumItalic', serif;
$serif-heavyItalic: 'CopernicusHeavyItalic', serif;
$serif-book: 'CopernicusBook', serif;
$serif-bookItalic: 'CopernicusBookItalic', serif;

/*
==============
Font Sizes
==============
*/
$font-size-base: 16px;

$font-size-h1: (
  \@s: 40px,
  \@m: 48px,
  extraSmall: 56px,
  small: 72px,
  base: 80px,
  ultraLarge: 144px
);

$font-size-h2: (
  \@s: 36px,
  \@m: 46px,
  base: 64px,
  large: 72px
);

$font-size-h3: (
  \@xs: 18px,
  \@s: 26px,
  \@m: 32px,
  extraSmall: 20px,
  small: 36px,
  mediumSmall: 42px,
  base: 48px
);

$font-size-h4: (
  \@s: 17px,
  \@m: 22px,
  extraSmall: 24px,
  small: 32px,
  mediumSmall: 34px,
  base: 36px,
  medium: 40px
);

$font-size-h5: (
  \@xs: 13px,
  \@s: 16px,
  small: 20px,
  base: 24px,
  medium: 28px
);

$font-size-h6: (
  \@m: 14px,
  extraSmall: 14px,
  small: 18px,
  base: 20px
);

$font-size-p: (
  extraSmall: 10px,
  small: 12px,
  mediumSmall: 14px,
  base: 16px,
  medium: 18px,
  mediumLarge: 20px,
  large: 24px,
  mediumExtraLarge: 30px,
  extraLarge: 32px,
  superLarge: 36px
);

$font-size-blockquote: (
  small: 22px,
  base: 32px
);

$font-size-a: (
  extraSmall: 12px,
  small: 14px,
  base: 16px,
  medium: 18px
);

$font-size-button: (
  \@m: 12px
);

/*
==============
Line Heights
==============
*/
$line-height-h1: (
  \@s: line-height(map($font-size-h1, \@s), 56px),
  \@m: line-height(map($font-size-h1, \@m), 60px),
  small: line-height(map($font-size-h1, small), 80px),
  base: line-height(map($font-size-h1, base), 92px),
  ultraLarge: line-height(map($font-size-h1, ultraLarge), 72px),
);

$line-height-h2: (
  \@s: line-height(map($font-size-h2, \@s), 36px),
  \@m: line-height(map($font-size-h2, \@m), 60px),
  base: line-height(map($font-size-h2, base), 76px),
  large: line-height(map($font-size-h2, large), 96px)
);

$line-height-h3: (
  \@xs: line-height(map($font-size-h3, \@xs), 28px),
  \@s: line-height(map($font-size-h3, \@s), 32px),
  extraSmall: line-height(map($font-size-h3, extraSmall), 32px),
  small: line-height(map($font-size-h3, small), 48px),
  mediumSmall: line-height(map($font-size-h3, mediumSmall), 52px),
  base: line-height(map($font-size-h3, base), 60px)
);

$line-height-h4: (
  \@s: line-height(map($font-size-h4, \@s), 28px),
  \@m: line-height(map($font-size-h4, \@m), 32px),
  extraSmall: line-height(map($font-size-h4, extraSmall), 32px),
  small: line-height(map($font-size-h4, base), 48px),
  mediumSmall: line-height(map($font-size-h4, mediumSmall), 59px),
  base: line-height(map($font-size-h4, base), 69px),
  medium: line-height(map($font-size-h4, medium), 48px)
);

$line-height-h5: (
  \@xs: line-height(map($font-size-h5, \@xs), 25px),
  \@s: line-height(map($font-size-h5, \@s), 40px),
  small: line-height(map($font-size-h5, small), 29px),
  base: line-height(map($font-size-h5, base), 36px),
  medium: line-height(map($font-size-h5, medium), 42px)
);

$line-height-h6: (
  \@m: line-height(map($font-size-h6, \@m), 60px),
  small: line-height(map($font-size-h6, small), 36px),
  base: line-height(map($font-size-h6, base), 60px)
);

$line-height-p: (
  \@xs: line-height(map($font-size-p, small), 18px),
  \@s: line-height(map($font-size-p, mediumSmall), 28px),
  \@m: line-height(map($font-size-p, medium), 36px),
  extraSmall: line-height(map($font-size-p, extraSmall), 24px),
  small: line-height(map($font-size-p, small), 16px),
  base: line-height(map($font-size-p, base), 24px),
  mediumSmall: line-height(map($font-size-p, mediumSmall), 36px),
  medium: line-height(map($font-size-p, base), 32px),
  mediumLarge: line-height(map($font-size-p, mediumLarge), 36px),
  large: line-height(map($font-size-p, large), 48px),
  mediumExtraLarge: line-height(map($font-size-p, mediumExtraLarge), 50px),
  extraLarge: line-height(map($font-size-p, extraLarge), 48px),
  superLarge: line-height(map($font-size-p, superLarge), 70px)
);

$line-height-blockquote: (
  small: line-height(map($font-size-blockquote, small), 36px),
  base: line-height(map($font-size-blockquote, base), 48px),
);

$line-height-a: (
  superSmall: line-height(map($font-size-a, extraSmall), 14px),
  extraSmall: line-height(map($font-size-a, extraSmall), 18px),
  small: line-height(map($font-size-a, small), 30px),
  base: line-height(map($font-size-a, base), 32px),
  medium: line-height(map($font-size-a, medium), 28px)
);

/*
==============
Colors
==============
*/
$color-white: (
  base: #fff
);

$color-black: (
  base: #000
);

$color-gray: (
  ultraLight: #f3f3f3,
  superLight: #e5e8f0,
  extraLight: #c2c4c4,
  light: #ACACAC,
  base: #636466,
  medium: #77787b,
  mediumDark: #595959,
  dark: #4d4d4f,
  extraDark: #3f444b,
  superDark: #111111,
  diverse: #4a4546,
  lockup: #5f6062
);

$color-blue: (
  megaLight: #eff6fc,
  ultraLight: #d7e3ed,
  superLight: #c6dae1,
  sharpLight: #cfedfb,
  extraLight: #afd4ee,
  light: #a6cde9,
  mediumLight: #9edcf8,
  base: #034ea1,
  baseMedium: #1f57a8,
  medium: #012169,
  mediumDark: #001e6b,
  dark: #00205b,
  superDark: #1a1d67,
  extraDark: #00274a,
  ultraDark: #0f0f19,
  inquisitive: #0186ae,
  independent: #00a5aa,
  passionate: #91b3ce,
  lockup: #222d65
);

$color-green: (
  light: #c3d68f,
  base: #a4c055,
  medium: #a5c155,
  driven: #5eab72
);

$color-purple: (
  base: #807fb9,
  dark: #14143e
);

$bg-color-alert: (
  primary: #00205B,
  secondary: #C1C6C8,
  success: #389346,
  danger: #C04729,
  warning: #EDA622,
  info: #4D99D2,
  light: #DFE3E6,
  dark: #3E444A
);

$color-alert: (
  secondary: #00205B,
  warning: #000,
  light: #00205B
);

$color-red: (
  base: #ff0000
);

/*
==============
Grid
==============
*/
$breakpoint-xxl: '(max-width: 165em)'; // 2640px
$breakpoint-xl: '(max-width: 120em)'; // 1920px
$breakpoint-xlm: '(max-width: 97em)'; // 1552px
$breakpoint-lg: '(max-width: 90.625em)'; // 1450px
$breakpoint-ml: '(max-width: 75em)'; // 1200px
$breakpoint-md: '(max-width: 64em)'; // 1024px
$breakpoint-ms: '(max-width: 48em)'; // 768px
$breakpoint-sm: '(max-width: 35em)'; // 560px
$breakpoint-xs: '(max-width: 26em)'; // 416px
$breakpoint-lg-min: '(min-width: 64em) and (max-width: 90em)';
$breakpoint-md-min: '(min-width: 64.1em)';
$breakpoint-ms-min: '(min-width: 48em) and (max-width: 64em)';
$breakpoint-sm-min: '(min-width: 35em) and (max-width: 48em)';
$breakpoint-hero-video: '(min-width: 103em)'; // 1648px

$grid-hero-video: (
  columns: 12,
  gutter: 0,
  media: $breakpoint-hero-video
);

$grid-xlm: (
  columns: 12,
  gutter: 0,
  media: $breakpoint-xlm
);

$grid-xxl: (
  columns: 12,
  gutter: 0,
  media: $breakpoint-xxl
);

$grid-lg-min: (
  columns: 12,
  gutter: 0,
  media: $breakpoint-lg-min
);

$grid-xl: (
  columns: 12,
  gutter: 0,
  media: $breakpoint-xl
);

$grid-lg: (
  columns: 12,
  gutter: 0,
  media: $breakpoint-lg
);

$grid-ml: (
  columns: 12,
  gutter: 0,
  media: $breakpoint-ml
);

$grid-md: (
  columns: 8,
  gutter: 0,
  media: $breakpoint-md
);

$grid-md-min: (
  columns: 8,
  gutter: 0,
  media: $breakpoint-md-min
);

$grid-ms: (
  columns: 6,
  gutter: 0,
  media: $breakpoint-ms
);

$grid-ms-min: (
  columns: 6,
  gutter: 0,
  media: $breakpoint-ms-min
);

$grid-sm: (
  columns: 4,
  gutter: 0,
  media: $breakpoint-sm
);

$grid-sm-min: (
  columns: 4,
  gutter: 0,
  media: $breakpoint-sm-min
);

$grid-xs: (
  columns: 4,
  gutter: 0,
  media: $breakpoint-xs
);

$grid-lg-5: (
  columns: 5,
  gutter: 0,
  media: $breakpoint-lg
);
