.video-embed {
  width: 100%;
  padding: 0 40px;

  & > iframe {
    display: block;
    margin: 0 auto;
    margin-bottom: 60px;
    width: 100%;
    max-width: 850px;
    min-height: 480px;
    height: auto;

    @include grid-media($grid-ms) {
      min-height: 320px;
    }

    @include grid-media($grid-sm) {
      min-height: 240px;
    }
  }
}

.video-responsive {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin-top: 3%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.video {
  $self: &;
  position: relative;
  cursor: pointer;

  @include grid-media($grid-md) {

    h1 {
      font-size: rem(map($font-size-h1, \@s));
      line-height: map($line-height-h1, \@s);
    }

    h4 {
      font-size: rem(map($font-size-h4, \@s));
      line-height: map($line-height-h4, \@s);
    }

    h5 {
      font-size: rem(map($font-size-h5, \@xs));
      line-height: map($line-height-h5, \@xs);
    }
  }

  // Blocks
  &-overlay {
    @extend .overlay;

    &__content {
      @extend .overlay__content;
      padding: 0 60px;

      h1 {
        float: right;
      }

      h3 {
        color: map($color-white, base);
      }

      h4 {
        float: left;
        width: 60%;
        text-align: left;
        line-height: map($line-height-h4, small);

        & + a {
          width: 40%;
        }
      }
    }
  }

  // Elements
  &__image, &__title {
    position: relative;
  }

  // Modifiers
  &--large {
    min-height: 900px;
    float: left;
    width: 75%;
    @extend .border-r;

    &:hover {

      #{ $self }__title {

        strong {
          color: map($color-green, base);
        }
      }
    }

    #{ $self }__title {
      float: left;
      width: 70%;

      strong {
        transition: color .3s ease;
      }

      @include grid-media($grid-md) {
        float: none;
        width: 100%;
      }
    }

    .button-group {
      position: relative;
      float: left;
      top: 5px;
      width: 30%;
    }

    @include grid-media($grid-lg) {
      float: none;
      min-height: 500px;
      width: 100%;
      border-right: none;
    }

    @include grid-media($grid-md) {
      h1 {
        float: none;
        width: 100%;
        text-align: center;
      }

      .button-group {
        display: block;
        float: none;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 100px;

        &__text {
          display: none;
        }
      }
    }
  }

  &--thumbs {
    float: left;
    width: 25%;

    @include grid-media($grid-lg) {
      float: none;
      width: 100%;
      border-bottom: solid 1px map($color-blue, extraLight);
    }
  }

  &--thumb {
    min-height: calc(900px / 4);

    &:hover {

      #{ $self }__title {

        strong {
          color: map($color-green, base);
        }
      }
    }

    &:not(:first-child) {
      @extend .border-t;
    }

    #{ $self }__title {
      font-family: $serif-book;

      strong {
        font-family: $serif-extraBold;
        transition: color .3s ease;
      }
    }

    @include grid-media($grid-lg) {
      min-height: 200px;
      float: left;
      width: 50%;
      border-top: solid 1px map($color-blue, extraLight);

      &:nth-child(odd) {
        border-right: solid 1px map($color-blue, extraLight);
      }
    }

    @include grid-media($grid-sm) {
      min-height: 100px;
    }
  }

  &--featured {
    min-height: 450px;
    overflow: hidden;

    &:hover {

      #{ $self }__image {
        transform: scale(1.1);
      }
    }

    #{ $self }__image {
      @extend .image-background;
      position: absolute;
      transition: transform .3s ease;
    }

    .button-group {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    @include grid-media($grid-md) {
      min-height: 200px;

      .button-group {

        .button {
          transform: scale(.5);
        }

        .button-group__text {
          margin: -10px 0 0 0;
        }
      }
    }
  }

  &--callout {

    &:nth-child(even) {

      #{ $self }__title {
        background-color: map($color-blue, base);
      }
    }

    &:hover {

      .overlay--light {
        background: none;
      }

      #{ $self }__image .image-background {
        filter: grayscale(0);
      }

      #{ $self }__title {
        background-color: map($color-blue, extraLight);

        h3 {
          color: map($color-blue, dark);
        }
      }
    }

    #{ $self }__image {

      .image-zoom {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      @include grid-media($grid-md) {

        .overlay__content {
          padding: 0;

          &--left {
            left: -40px;
          }

          &--right {
            right: -40px;
            z-index: 999;
          }
        }

        .button-group {
          transform: scale(.5);
        }
      }
    }

    #{ $self }__image, #{ $self }__title {
      min-height: 450px;

      @include grid-media($grid-md) {
        min-height: 200px;
      }
    }

    #{ $self }__image .image-background {
      position: absolute;
      filter: grayscale(100%);
      transition: all .3s ease;
    }

    #{ $self }__title {
      background-color: map($color-blue, medium);
      transition: background-color .3s ease;

      h3 {
        transition: color .3s ease;

        @include grid-media($grid-md) {
          font-size: rem(map($font-size-h3, \@xs));
          line-height: map($line-height-h3, \@xs);
        }
      }
    }

    #{ $self }__overlay {
      padding: 0 0 40px 30px;
    }

    #{ $self }-overlay {

      &__content {

        @include grid-media($grid-md) {
          width: 100%;
          padding: 0 25px;
        }
      }
    }
  }

  &-mosaic:focus-visible {
    border: 4px solid #a4c055;
    z-index: 100;
  }
}

.fancybox {
  &-button {
    &:focus-visible {
      z-index: 999999 !important;
      outline-offset: -4px;
    }

    &--arrow_left, &--arrow_right {
      height: 70px !important;
      width: 50px !important;
      padding: .5em !important;
    }

    &--play, &--thumbs {
      display: none;
    }
  }
}
