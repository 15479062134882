.file-tree > .views-form > form > .form-actions {
  display: none;
}

.tree {
  $self: &;

  .col > .tree__item {
    margin-top: 0;
  }

  .subtree-0, .subtree-2 {
    display: none;

    &.is-active {
      display: block;
    }
  }

  .subtree-0 {
    margin-left: 1.25em;
  }

  .subtree-2 {
    margin-left: 2.5em;
  }

  .js-tree-button {
    cursor: pointer;

    &.is-active {
      .tree__button:before {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }

  &__item {
    margin: .625em 0;
    text-transform: uppercase;

    @include grid-media($grid-md) {
      padding-right: .5em;
    }

    span, a {
      font-size: rem(map($font-size-p, base));
      color: map($color-blue, superDark);
    }

    span {
      font-family: $sans-serif-black;
    }

    a {
      line-height: 1.2;
      border-bottom: solid 1px transparent;
      transition: all .3s ease;
      
      &:hover {
        color: map($color-green, base);
        border-bottom: solid 1px map($color-blue, superDark);
      }
    }
  }

  &__button {
    display: inline-block;
    position: relative;
    top: 1px;
    width: 18px;
    height: 18px;
    margin-left: 12px;
    border: solid 1px map($color-blue, superDark);
    border-radius: 50%;
    cursor: pointer;

    &:before, &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 1px;
      background: map($color-blue, superDark);
      transition: all .15s ease;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}