.animate {

  &-spin {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
  }
}

@keyframes spin {
  from {
      transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
      transform: translate(-50%, -50%) rotate(360deg);
  }
}