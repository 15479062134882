/*
==================
Margin
==================
*/
.m {
  &-1 {
    margin: 1%;
  }

  &-2 {
    margin: 2%;
  }

  &-3 {
    margin: 3%;
  }

  &-4 {
    margin: 4%;
  }

  &-5 {
    margin: 5%;
  }

  &-6 {
    margin: 6%;
  }

  &-7 {
    margin: 7%;
  }

  &-8 {
    margin: 8%;
  }

  &-9 {
    margin: 9%;
  }

  &-10 {
    margin: 10%;
  }
  
  &\@m {
    @include grid-media($grid-md) {
      &-rs {
        margin: 0 !important;
      }
    }
  }
  /*
  ==================
  Margin Horizontal
  ==================
  */
  &h {
    &-auto {
      margin: 0 auto;
    }
    &-1 {
      margin-left: 1%;
      margin-right: 1%;
    }

    &-2 {
      margin-left: 2%;
      margin-right: 2%;
    }

    &-3 {
      margin-left: 3%;
      margin-right: 3%;
    }

    &-4 {
      margin-left: 4%;
      margin-right: 4%;
    }

    &-5 {
      margin-left: 5%;
      margin-right: 5%;
    }

    &-6 {
      margin-left: 6%;
      margin-right: 6%;
    }

    &-7 {
      margin-left: 7%;
      margin-right: 7%;
    }

    &-8 {
      margin-left: 8%;
      margin-right: 8%;
    }

    &-9 {
      margin-left: 9%;
      margin-right: 9%;
    }

    &-10 {
      margin-left: 10%;
      margin-right: 10%;
    }
     /*
    =================================
    Margin Horizontal - Break Points
    =================================
    */
    &\@ml {
      @include grid-media($grid-ml) {
        &-rs {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        &-1 {
          margin-left: 1%;
          margin-right: 1%;
        }

        &-2 {
          margin-left: 2%;
          margin-right: 2%;
        }

        &-3 {
          margin-left: 3%;
          margin-right: 3%;
        }

        &-4 {
          margin-left: 4%;
          margin-right: 4%;
        }

        &-5 {
          margin-left: 5%;
          margin-right: 5%;
        }

        &-6 {
          margin-left: 6%;
          margin-right: 6%;
        }

        &-7 {
          margin-left: 7%;
          margin-right: 7%;
        }

        &-8 {
          margin-left: 8%;
          margin-right: 8%;
        }

        &-9 {
          margin-left: 9%;
          margin-right: 9%;
        }

        &-10 {
          margin-left: 10%;
          margin-right: 10%;
        }
      }
    }
    &\@m {
      @include grid-media($grid-md) {
        &-rs {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        &-1 {
          margin-left: 1%;
          margin-right: 1%;
        }

        &-2 {
          margin-left: 2%;
          margin-right: 2%;
        }

        &-3 {
          margin-left: 3%;
          margin-right: 3%;
        }

        &-4 {
          margin-left: 4%;
          margin-right: 4%;
        }

        &-5 {
          margin-left: 5%;
          margin-right: 5%;
        }

        &-6 {
          margin-left: 6%;
          margin-right: 6%;
        }

        &-7 {
          margin-left: 7%;
          margin-right: 7%;
        }

        &-8 {
          margin-left: 8%;
          margin-right: 8%;
        }

        &-9 {
          margin-left: 9%;
          margin-right: 9%;
        }

        &-10 {
          margin-left: 10%;
          margin-right: 10%;
        }
      }
    }
    // Small
    &\@s {
      @include grid-media($grid-sm) {
        &-rs {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        &-1 {
          margin-left: 1%;
          margin-right: 1%;
        }

        &-2 {
          margin-left: 2%;
          margin-right: 2%;
        }

        &-3 {
          margin-left: 3%;
          margin-right: 3%;
        }

        &-4 {
          margin-left: 4%;
          margin-right: 4%;
        }

        &-5 {
          margin-left: 5%;
          margin-right: 5%;
        }

        &-6 {
          margin-left: 6%;
          margin-right: 6%;
        }

        &-7 {
          margin-left: 7%;
          margin-right: 7%;
        }

        &-8 {
          margin-left: 8%;
          margin-right: 8%;
        }

        &-9 {
          margin-left: 9%;
          margin-right: 9%;
        }

        &-10 {
          margin-left: 10%;
          margin-right: 10%;
        }
      }
    }
  }
  /*
  ================
  Margin Vertical
  ================
  */
  &v {
    &-1 {
      margin-top: 1%;
      margin-bottom: 1%;
    }

    &-2 {
      margin-top: 2%;
      margin-bottom: 2%;
    }

    &-3 {
      margin-top: 3%;
      margin-bottom: 3%;
    }

    &-4 {
      margin-top: 4%;
      margin-bottom: 4%;
    }

    &-5 {
      margin-top: 5%;
      margin-bottom: 5%;
    }

    &-6 {
      margin-top: 6%;
      margin-bottom: 6%;
    }

    &-7 {
      margin-top: 7%;
      margin-bottom: 7%;
    }

    &-8 {
      margin-top: 8%;
      margin-bottom: 8%;
    }

    &-9 {
      margin-top: 9%;
      margin-bottom: 9%;
    }

    &-10 {
      margin-top: 10%;
      margin-bottom: 10%;
    }
    /*
    ===============================
    Margin Vertical - Break Points
    ===============================
    */
    // Medium
    &\@m {
      @include grid-media($grid-md) {
        &-rs {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
        
        &-1 {
          margin-top: 1%;
          margin-bottom: 1%;
        }

        &-2 {
          margin-top: 2%;
          margin-bottom: 2%;
        }

        &-3 {
          margin-top: 3%;
          margin-bottom: 3%;
        }

        &-4 {
          margin-top: 4%;
          margin-bottom: 4%;
        }

        &-5 {
          margin-top: 5%;
          margin-bottom: 5%;
        }

        &-6 {
          margin-top: 6%;
          margin-bottom: 6%;
        }

        &-7 {
          margin-top: 7%;
          margin-bottom: 7%;
        }

        &-8 {
          margin-top: 8%;
          margin-bottom: 8%;
        }

        &-9 {
          margin-top: 9%;
          margin-bottom: 9%;
        }

        &-10 {
          margin-top: 10%;
          margin-bottom: 10%;
        }
      }
    }
    // Small
    &\@s {
      @include grid-media($grid-sm) {
        &-rs {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }

        &-1 {
          margin-top: 1%;
          margin-bottom: 1%;
        }

        &-2 {
          margin-top: 2%;
          margin-bottom: 2%;
        }

        &-3 {
          margin-top: 3%;
          margin-bottom: 3%;
        }

        &-4 {
          margin-top: 4%;
          margin-bottom: 4%;
        }

        &-5 {
          margin-top: 5%;
          margin-bottom: 5%;
        }

        &-6 {
          margin-top: 6%;
          margin-bottom: 6%;
        }

        &-7 {
          margin-top: 7%;
          margin-bottom: 7%;
        }

        &-8 {
          margin-top: 8%;
          margin-bottom: 8%;
        }

        &-9 {
          margin-top: 9%;
          margin-bottom: 9%;
        }

        &-10 {
          margin-top: 10%;
          margin-bottom: 10%;
        }
      }
    }
  }
  /*
  ============
  Margin Left
  ============
  */
  &l {
    &-1 {
      margin-left: 1%;
    }

    &-2 {
      margin-left: 2%;
    }

    &-3 {
      margin-left: 3%;
    }

    &-4 {
      margin-left: 4%;
    }

    &-5 {
      margin-left: 5%;
    }

    &-6 {
      margin-left: 6%;
    }

    &-7 {
      margin-left: 7%;
    }

    &-8 {
      margin-left: 8%;
    }

    &-9 {
      margin-left: 9%;
    }

    &-10 {
      margin-left: 10%;
    }

        /*
    ==========================
    Margin Right - Break Points
    ==========================
    */
    // Medium
    &\@m {
      @include grid-media($grid-md) {
        &-rs {
          margin-left: 0 !important;
        }

        &-1 {
          margin-left: 1%;
        }

        &-2 {
          margin-left: 2%;
        }

        &-3 {
          margin-left: 3%;
        }

        &-4 {
          margin-left: 4%;
        }

        &-5 {
          margin-left: 5%;
        }

        &-6 {
          margin-left: 6%;
        }

        &-7 {
          margin-left: 7%;
        }

        &-8 {
          margin-left: 8%;
        }

        &-9 {
          margin-left: 9%;
        }

        &-10 {
          margin-left: 10%;
        }
      }
    }
    // Small
    &\@s {
      @include grid-media($grid-sm) {
        &-rs {
          margin-left: 0 !important;
        }

        &-1 {
          margin-left: 1%;
        }

        &-2 {
          margin-left: 2%;
        }

        &-3 {
          margin-left: 3%;
        }

        &-4 {
          margin-left: 4%;
        }

        &-5 {
          margin-left: 5%;
        }

        &-6 {
          margin-left: 6%;
        }

        &-7 {
          margin-left: 7%;
        }

        &-8 {
          margin-left: 8%;
        }

        &-9 {
          margin-left: 9%;
        }

        &-10 {
          margin-left: 10%;
        }
      }
    }
  }
  /*
  =============
  Margin Right
  =============
  */
  &r {
    &-1 {
      margin-right: 1%;
    }

    &-2 {
      margin-right: 2%;
    }

    &-3 {
      margin-right: 3%;
    }

    &-4 {
      margin-right: 4%;
    }

    &-5 {
      margin-right: 5%;
    }

    &-6 {
      margin-right: 6%;
    }

    &-7 {
      margin-right: 7%;
    }

    &-8 {
      margin-right: 8%;
    }

    &-9 {
      margin-right: 9%;
    }

    &-10 {
      margin-right: 10%;
    }
    /*
    ==========================
    Margin Right - Break Points
    ==========================
    */
    // Medium
    &\@m {
      @include grid-media($grid-md) {
        &-rs {
          margin-right: 0 !important;
        }

        &-1 {
          margin-right: 1%;
        }

        &-2 {
          margin-right: 2%;
        }

        &-3 {
          margin-right: 3%;
        }

        &-4 {
          margin-right: 4%;
        }

        &-5 {
          margin-right: 5%;
        }

        &-6 {
          margin-right: 6%;
        }

        &-7 {
          margin-right: 7%;
        }

        &-8 {
          margin-right: 8%;
        }

        &-9 {
          margin-right: 9%;
        }

        &-10 {
          margin-right: 10%;
        }
      }
    }
    // Small
    &\@s {
      @include grid-media($grid-sm) {
        &-rs {
          margin-right: 0 !important;
        }

        &-1 {
          margin-right: 1%;
        }

        &-2 {
          margin-right: 2%;
        }

        &-3 {
          margin-right: 3%;
        }

        &-4 {
          margin-right: 4%;
        }

        &-5 {
          margin-right: 5%;
        }

        &-6 {
          margin-right: 6%;
        }

        &-7 {
          margin-right: 7%;
        }

        &-8 {
          margin-right: 8%;
        }

        &-9 {
          margin-right: 9%;
        }

        &-10 {
          margin-right: 10%;
        }
      }
    }
  }
  /*
  ===========
  Margin Top
  ===========
  */
  &t {
    &-1 {
      margin-top: 1%;
    }

    &-2 {
      margin-top: 2%;
    }

    &-3 {
      margin-top: 3%;
    }

    &-4 {
      margin-top: 4%;
    }

    &-5 {
      margin-top: 5%;
    }

    &-6 {
      margin-top: 6%;
    }

    &-7 {
      margin-top: 7%;
    }

    &-8 {
      margin-top: 8%;
    }

    &-9 {
      margin-top: 9%;
    }

    &-10 {
      margin-top: 10%;
    }
    /*
    ==========================
    Margin Top - Break Points
    ==========================
    */
    // Medium
    &\@m {
      @include grid-media($grid-md) {
        &-rs {
          margin-top: 0 !important;
        }

        &-1 {
          margin-top: 1%;
        }

        &-2 {
          margin-top: 2%;
        }

        &-3 {
          margin-top: 3%;
        }

        &-4 {
          margin-top: 4%;
        }

        &-5 {
          margin-top: 5%;
        }

        &-6 {
          margin-top: 6%;
        }

        &-7 {
          margin-top: 7%;
        }

        &-8 {
          margin-top: 8%;
        }

        &-9 {
          margin-top: 9%;
        }

        &-10 {
          margin-top: 10%;
        }
      }
    }
    // Small
    &\@s {
      @include grid-media($grid-sm) {
        &-rs {
          margin-top: 0 !important;
        }

        &-1 {
          margin-top: 1%;
        }

        &-2 {
          margin-top: 2%;
        }

        &-2 {
          margin-top: 3%;
        }

        &-4 {
          margin-top: 4%;
        }

        &-5 {
          margin-top: 5%;
        }

        &-6 {
          margin-top: 6%;
        }

        &-7 {
          margin-top: 7%;
        }

        &-8 {
          margin-top: 8%;
        }

        &-9 {
          margin-top: 9%;
        }

        &-10 {
          margin-top: 10%;
        }
      }
    }
  }
  /*
  ==============
  Margin Bottom
  ==============
  */
  &b {
    &-1 {
      margin-bottom: 1%;
    }

    &-2 {
      margin-bottom: 2%;
    }

    &-3 {
      margin-bottom: 3%;
    }

    &-4 {
      margin-bottom: 4%;
    }

    &-5 {
      margin-bottom: 5%;
    }

    &-6 {
      margin-bottom: 6%;
    }

    &-7 {
      margin-bottom: 7%;
    }

    &-8 {
      margin-bottom: 8%;
    }

    &-9 {
      margin-bottom: 9%;
    }

    &-10 {
      margin-bottom: 10%;
    }
    /*
    =============================
    Margin Bottom - Break Points
    =============================
    */
    // Medium
    &\@m {
      @include grid-media($grid-md) {
        &-rs {
          margin-bottom: 0 !important;
        }

        &-1 {
          margin-bottom: 1%;
        }

        &-2 {
          margin-bottom: 2%;
        }

        &-3 {
          margin-bottom: 3%;
        }

        &-4 {
          margin-bottom: 4%;
        }

        &-5 {
          margin-bottom: 5%;
        }

        &-6 {
          margin-bottom: 6%;
        }

        &-7 {
          margin-bottom: 7%;
        }

        &-8 {
          margin-bottom: 8%;
        }

        &-9 {
          margin-bottom: 9%;
        }

        &-10 {
          margin-bottom: 10%;
        }
      }
    }
    // Small
    &\@s {
      @include grid-media($grid-sm) {
        &-rs {
          margin-bottom: 0 !important;
        }

        &-1 {
          margin-bottom: 1%;
        }

        &-2 {
          margin-bottom: 2%;
        }

        &-3 {
          margin-bottom: 3%;
        }

        &-4 {
          margin-bottom: 4%;
        }

        &-5 {
          margin-bottom: 5%;
        }

        &-6 {
          margin-bottom: 6%;
        }

        &-7 {
          margin-bottom: 7%;
        }

        &-8 {
          margin-bottom: 8%;
        }

        &-9 {
          margin-bottom: 9%;
        }

        &-10 {
          margin-bottom: 10%;
        }
      }
    }
  }
  /*
  ===============
  Margin - Reset
  ===============
  */
  &-rs {
    @include grid-media($grid-md) {
      margin: 0 !important;
    }
    // Medium
    &\@m {
      @include grid-media($grid-md) {
        margin: 0 !important;
      }
    }
    // Small
    &\@s {
      @include grid-media($grid-sm) {
        margin: 0 !important;
      }
    }
  }
}