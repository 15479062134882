.t {
  // Line Height
  &lh1\.5 {
    &\@m {
      @include grid-media($grid-md) {
          line-height: 1.5 !important;
      }
    }
  }

  &lh2 {
    &\@s {
      @include grid-media($grid-sm) {
          line-height: 2.25 !important;
      }
    }
    &\@m {
      @include grid-media($grid-md) {
          line-height: 2.25 !important;
      }
    }
    &\@l {
      @include grid-media($grid-lg) {
          line-height: 2.25 !important;
      }
    }
  }

  // Text Columns
  &c {

    p {
      overflow: hidden; /* fix for Firefox */
      break-inside: avoid-column;
    }

    &-2 {
      column-count: 2;
      column-gap: 32px;
    }

    &-3 {
      column-fill: balance;
      column-count: 3;
      column-gap: 32px;
    }

    // Reset
    &-rs {
      column-count: 1 !important;
      column-gap: 0 !important;
    }

    &\@s {
      @include grid-media($grid-sm) {
        &-2 {
          column-count: 2 !important;
        }
        &-3 {
          column-count: 3 !important;
        }
        &-rs {
          column-count: 1 !important;
        }
      }
    }

    &\@m {
      @include grid-media($grid-md) {
        &-2 {
          column-count: 2 !important;
        }
        &-3 {
          column-count: 3 !important;
        }
        &-rs {
          column-count: 1 !important;
        }
      }
    }

    &\@l {
      @include grid-media($grid-lg) {
        &-2 {
          column-count: 2 !important;
        }
        &-3 {
          column-count: 3 !important;
        }
        &-rs {
          column-count: 1 !important;
        }
      }
    }
  }

  // Align
  &a {

    &j {
      text-align: justify !important;
    }

    // Left
    &l {
      text-align: left !important;

      // Breakpoint Medium
      &\@m {
        @include grid-media($grid-md) {
          text-align: left !important;
        }
      }
      // Breakpoint Small
      &\@s {
        @include grid-media($grid-sm) {
          text-align: left !important;
        }
      }
      // Breakpoint Large
      &\@l {
        @include grid-media($grid-lg) {
          text-align: left !important;
        }
      }
      // Breakpoint Medium Large
      &\@ml {
        @include grid-media($grid-ml) {
          text-align: left !important;
        }
      }
      // Breakpoint XL
      &\@xl {
        @include grid-media($grid-xl) {
          text-align: left !important;
        }
      }
    }

    // Center
    &c {
      text-align: center;

      // Breakpoint Large
      &\@l {
        @include grid-media($grid-lg) {
          text-align: center !important;
        }
      }
      // Breakpoint Medium Large
      &\@ml {
        @include grid-media($grid-ml) {
          text-align: center !important;
        }
      }
      // Breakpoint Medium
      &\@m {
        @include grid-media($grid-md) {
          text-align: center !important;
        }
      }
      // Breakpoint Small
      &\@s {
        @include grid-media($grid-sm) {
          text-align: center !important;
        }
      }
    }

    // Right
    &r {
      text-align: right;

      // Breakpoint Medium
      &\@m {
        @include grid-media($grid-md) {
          text-align: right !important;
        }
      }
      // Breakpoint Small
      &\@s {
        @include grid-media($grid-sm) {
          text-align: center !important;
        }
      }
    }
  }
}

// Drop Caps
.dropcaps {
  float: left;
  color: map($color-blue, dark);
  font-family: $serif-medium;
  font-size: rem(map($font-size-h1, ultraLarge));
  line-height: map($line-height-h1, ultraLarge);
  margin: 30px 0 10px 0;
  padding: 0 15px 5px 0;

  @include grid-media($grid-sm) {
    font-size: rem(map($font-size-h1, base));
    margin: 20px 0 0 0;
  }

}
