.js-gallery-grid {
  will-change: transform;
  transform: translateX(0);
  transition: transform .5s ease;
  margin-bottom: 5em;

  &.slide {
    transform: translateX(-100%);
  }
}

.js-gallery-view-wrapper {
  position: absolute;
  width: 100%;
}

.js-gallery-view {
  will-change: transform;
  transform: translateX(100%);
  transition: transform .5s ease;

  &.slide {
    transform: translateX(0);
  }
}

.js-gallery-thumbs {
  display: flex;
  margin: 10px;
  will-change: transform;
  transform: translateX(100%);
  transition: transform .5s ease;

  &.slide {
    transform: translateX(0);
  }
}

.js-gallery-thumb {
  position: relative;
  margin: 10px;
  display: block;
  float: left;
  width: 12%;

  &:hover {

    p {
      opacity: 1;
    }
  }

  &:hover, &.is-active {

    &::after {
      background-color: transparent;
    }
  }

  &.is-active {

    &::before {
      background-color: map($color-green, base);
    }

    p {
      opacity: 1;
    }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    transition: background-color .3s ease;
  }

  &::before {
    height: 10px;
  }

  &::after {
    height: 100%;
    background-color: rgba(map($color-blue, ultraDark), .5);
  }

  p {
    position: absolute;
    top: 100%;
    margin-top: 10px;
    color: map($color-blue, mediumDark);
    opacity: 0;
    transition: opacity .3s ease;;
  }
}

.js-gallery-button {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: map($color-blue, base);
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0;
  }

  @include grid-media($grid-md) {
    display: block;
  }
}

.js-gallery-prev {

  &::before {
    @include triangle(20px, map($color-white, base), left);
    margin-left: -2px;
  }
}

.js-gallery-next {
  left: auto;
  right: 0;
  
  &::before {
    @include triangle(20px, map($color-white, base), right);
    margin-left: 2px;
  }
}