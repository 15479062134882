.w {
  &\@m {
    @include grid-media($grid-md) {
      &-100 {
        width: 100%;
      }
    }
  }

  &\@sm {
    @include grid-media($grid-sm) {
      &-100 {
        width: 100%;
      }
    }
  }
}
