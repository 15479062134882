.p {
  &-1 {
    padding: 1%;
  }

  &-2 {
    padding: 2%;
  }

  &-3 {
    padding: 3%;
  }

  &-4 {
    padding: 4%;
  }

  &-5 {
    padding: 5%;
  }

  &-6 {
    padding: 6%;
  }

  &-7 {
    padding: 7%;
  }

  &-8 {
    padding: 8%;
  }

  &-9 {
    padding: 9%;
  }

  &-10 {
    padding: 10%;
  }
  &\@m {
    @include grid-media($grid-md) {
      &-rs {
        padding: 0 !important;
      }
      &-1 {
        padding: 1%;
      }
    
      &-2 {
        padding: 2%;
      }
    
      &-3 {
        padding: 3%;
      }
    
      &-4 {
        padding: 4%;
      }
    
      &-5 {
        padding: 5% !important;
      }
    
      &-6 {
        padding: 6%;
      }
    
      &-7 {
        padding: 7%;
      }
    
      &-8 {
        padding: 8%;
      }
    
      &-9 {
        padding: 9%;
      }
    
      &-10 {
        padding: 10%;
      }
    }
  }
  /*
  ==================
  Padding Horizontal
  ==================
  */
  &h {
    &-1 {
      padding-left: 1%;
      padding-right: 1%;
    }

    &-2 {
      padding-left: 2%;
      padding-right: 2%;
    }

    &-3 {
      padding-left: 3%;
      padding-right: 3%;
    }

    &-4 {
      padding-left: 4%;
      padding-right: 4%;
    }

    &-5 {
      padding-left: 5%;
      padding-right: 5%;
    }

    &-6 {
      padding-left: 6%;
      padding-right: 6%;
    }

    &-7 {
      padding-left: 7%;
      padding-right: 7%;
    }

    &-8 {
      padding-left: 8%;
      padding-right: 8%;
    }

    &-9 {
      padding-left: 9%;
      padding-right: 9%;
    }

    &-10 {
      padding-left: 10%;
      padding-right: 10%;
    }
    &-20 {
      padding-left: 20%;
      padding-right: 20%;
    }
     /*
    =================================
    Padding Horizontal - Break Points
    =================================
    */
    // Large
    &\@l {
      @include grid-media($grid-lg) {
        &-rs {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        &-1 {
          padding-left: 1%;
          padding-right: 1%;
        }

        &-2 {
          padding-left: 2%;
          padding-right: 2%;
        }

        &-3 {
          padding-left: 3%;
          padding-right: 3%;
        }

        &-4 {
          padding-left: 4%;
          padding-right: 4%;
        }

        &-5 {
          padding-left: 5%;
          padding-right: 5%;
        }

        &-6 {
          padding-left: 6%;
          padding-right: 6%;
        }

        &-7 {
          padding-left: 7%;
          padding-right: 7%;
        }

        &-8 {
          padding-left: 8%;
          padding-right: 8%;
        }

        &-9 {
          padding-left: 9%;
          padding-right: 9%;
        }

        &-10 {
          padding-left: 10%;
          padding-right: 10%;
        }
      }
    }
    // Medium
    &\@m {
      @include grid-media($grid-md) {
        &-rs {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        &-1 {
          padding-left: 1%;
          padding-right: 1%;
        }

        &-2 {
          padding-left: 2%;
          padding-right: 2%;
        }

        &-3 {
          padding-left: 3%;
          padding-right: 3%;
        }

        &-4 {
          padding-left: 4%;
          padding-right: 4%;
        }

        &-5 {
          padding-left: 5%;
          padding-right: 5%;
        }

        &-6 {
          padding-left: 6%;
          padding-right: 6%;
        }

        &-7 {
          padding-left: 7%;
          padding-right: 7%;
        }

        &-8 {
          padding-left: 8%;
          padding-right: 8%;
        }

        &-9 {
          padding-left: 9%;
          padding-right: 9%;
        }

        &-10 {
          padding-left: 10%;
          padding-right: 10%;
        }
      }
    }
    // Small
    &\@s {
      @include grid-media($grid-sm) {
        &-rs {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        &-1 {
          padding-left: 1%;
          padding-right: 1%;
        }

        &-2 {
          padding-left: 2%;
          padding-right: 2%;
        }

        &-3 {
          padding-left: 3%;
          padding-right: 3%;
        }

        &-4 {
          padding-left: 4%;
          padding-right: 4%;
        }

        &-5 {
          padding-left: 5%;
          padding-right: 5%;
        }

        &-6 {
          padding-left: 6%;
          padding-right: 6%;
        }

        &-7 {
          padding-left: 7%;
          padding-right: 7%;
        }

        &-8 {
          padding-left: 8%;
          padding-right: 8%;
        }

        &-9 {
          padding-left: 9%;
          padding-right: 9%;
        }

        &-10 {
          padding-left: 10%;
          padding-right: 10%;
        }
      }
    }
  }
  /*
  ================
  Padding Vertical
  ================
  */
  &v {
    &-1 {
      padding-top: 1%;
      padding-bottom: 1%;
    }

    &-2 {
      padding-top: 2%;
      padding-bottom: 2%;
    }

    &-3 {
      padding-top: 3%;
      padding-bottom: 3%;
    }

    &-4 {
      padding-top: 4%;
      padding-bottom: 4%;
    }

    &-5 {
      padding-top: 5%;
      padding-bottom: 5%;
    }

    &-6 {
      padding-top: 6%;
      padding-bottom: 6%;
    }

    &-7 {
      padding-top: 7%;
      padding-bottom: 7%;
    }

    &-8 {
      padding-top: 8%;
      padding-bottom: 8%;
    }

    &-9 {
      padding-top: 9%;
      padding-bottom: 9%;
    }

    &-10 {
      padding-top: 10%;
      padding-bottom: 10%;
    }
    /*
    ===============================
    Padding Vertical - Break Points
    ===============================
    */
    // Medium
    &\@m {
      @include grid-media($grid-md) {
        &-rs {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
        
        &-1 {
          padding-top: 1%;
          padding-bottom: 1%;
        }

        &-2 {
          padding-top: 2%;
          padding-bottom: 2%;
        }

        &-3 {
          padding-top: 3%;
          padding-bottom: 3%;
        }

        &-4 {
          padding-top: 4%;
          padding-bottom: 4%;
        }

        &-5 {
          padding-top: 5%;
          padding-bottom: 5%;
        }

        &-6 {
          padding-top: 6%;
          padding-bottom: 6%;
        }

        &-7 {
          padding-top: 7%;
          padding-bottom: 7%;
        }

        &-8 {
          padding-top: 8%;
          padding-bottom: 8%;
        }

        &-9 {
          padding-top: 9%;
          padding-bottom: 9%;
        }

        &-10 {
          padding-top: 10%;
          padding-bottom: 10%;
        }
      }
    }
    // Small
    &\@s {
      @include grid-media($grid-sm) {
        &-rs {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }

        &-1 {
          padding-top: 1%;
          padding-bottom: 1%;
        }

        &-2 {
          padding-top: 2%;
          padding-bottom: 2%;
        }

        &-3 {
          padding-top: 3%;
          padding-bottom: 3%;
        }

        &-4 {
          padding-top: 4%;
          padding-bottom: 4%;
        }

        &-5 {
          padding-top: 5%;
          padding-bottom: 5%;
        }

        &-6 {
          padding-top: 6%;
          padding-bottom: 6%;
        }

        &-7 {
          padding-top: 7%;
          padding-bottom: 7%;
        }

        &-8 {
          padding-top: 8%;
          padding-bottom: 8%;
        }

        &-9 {
          padding-top: 9%;
          padding-bottom: 9%;
        }

        &-10 {
          padding-top: 10%;
          padding-bottom: 10%;
        }
      }
    }
  }
  /*
  ============
  Padding Left
  ============
  */
  &l {
    &-1 {
      padding-left: 1%;
    }

    &-2 {
      padding-left: 2%;
    }

    &-3 {
      padding-left: 3%;
    }

    &-4 {
      padding-left: 4%;
    }

    &-5 {
      padding-left: 5%;
    }

    &-6 {
      padding-left: 6%;
    }

    &-7 {
      padding-left: 7%;
    }

    &-8 {
      padding-left: 8%;
    }

    &-9 {
      padding-left: 9%;
    }

    &-10 {
      padding-left: 10%;
    }

        /*
    ==========================
    Padding Right - Break Points
    ==========================
    */
    // Medium
    &\@m {
      @include grid-media($grid-md) {
        &-rs {
          padding-left: 0 !important;
        }

        &-1 {
          padding-left: 1%;
        }

        &-2 {
          padding-left: 2%;
        }

        &-3 {
          padding-left: 3%;
        }

        &-4 {
          padding-left: 4%;
        }

        &-5 {
          padding-left: 5%;
        }

        &-6 {
          padding-left: 6%;
        }

        &-7 {
          padding-left: 7%;
        }

        &-8 {
          padding-left: 8%;
        }

        &-9 {
          padding-left: 9%;
        }

        &-10 {
          padding-left: 10%;
        }
      }
    }
    // Small
    &\@s {
      @include grid-media($grid-sm) {
        &-rs {
          padding-left: 0 !important;
        }

        &-1 {
          padding-left: 1%;
        }

        &-2 {
          padding-left: 2%;
        }

        &-3 {
          padding-left: 3%;
        }

        &-4 {
          padding-left: 4%;
        }

        &-5 {
          padding-left: 5%;
        }

        &-6 {
          padding-left: 6%;
        }

        &-7 {
          padding-left: 7%;
        }

        &-8 {
          padding-left: 8%;
        }

        &-9 {
          padding-left: 9%;
        }

        &-10 {
          padding-left: 10%;
        }
      }
    }
  }
  /*
  =============
  Padding Right
  =============
  */
  &r {
    &-1 {
      padding-right: 1%;
    }

    &-2 {
      padding-right: 2%;
    }

    &-3 {
      padding-right: 3%;
    }

    &-4 {
      padding-right: 4%;
    }

    &-5 {
      padding-right: 5%;
    }

    &-6 {
      padding-right: 6%;
    }

    &-7 {
      padding-right: 7%;
    }

    &-8 {
      padding-right: 8%;
    }

    &-9 {
      padding-right: 9%;
    }

    &-10 {
      padding-right: 10%;
    }
    /*
    ==========================
    Padding Right - Break Points
    ==========================
    */
    // Medium
    &\@m {
      @include grid-media($grid-md) {
        &-rs {
          padding-right: 0 !important;
        }

        &-1 {
          padding-right: 1%;
        }

        &-2 {
          padding-right: 2%;
        }

        &-3 {
          padding-right: 3%;
        }

        &-4 {
          padding-right: 4%;
        }

        &-5 {
          padding-right: 5%;
        }

        &-6 {
          padding-right: 6%;
        }

        &-7 {
          padding-right: 7%;
        }

        &-8 {
          padding-right: 8%;
        }

        &-9 {
          padding-right: 9%;
        }

        &-10 {
          padding-right: 10%;
        }
      }
    }
    // Small
    &\@s {
      @include grid-media($grid-sm) {
        &-rs {
          padding-right: 0 !important;
        }

        &-1 {
          padding-right: 1%;
        }

        &-2 {
          padding-right: 2%;
        }

        &-3 {
          padding-right: 3%;
        }

        &-4 {
          padding-right: 4%;
        }

        &-5 {
          padding-right: 5%;
        }

        &-6 {
          padding-right: 6%;
        }

        &-7 {
          padding-right: 7%;
        }

        &-8 {
          padding-right: 8%;
        }

        &-9 {
          padding-right: 9%;
        }

        &-10 {
          padding-right: 10%;
        }
      }
    }
  }
  /*
  ===========
  Padding Top
  ===========
  */
  &t {
    &-1 {
      padding-top: 1%;
    }

    &-2 {
      padding-top: 2%;
    }

    &-3 {
      padding-top: 3%;
    }

    &-4 {
      padding-top: 4%;
    }

    &-5 {
      padding-top: 5%;
    }

    &-6 {
      padding-top: 6%;
    }

    &-7 {
      padding-top: 7%;
    }

    &-8 {
      padding-top: 8%;
    }

    &-9 {
      padding-top: 9%;
    }

    &-10 {
      padding-top: 10%;
    }
    /*
    ==========================
    Padding Top - Break Points
    ==========================
    */
    // Medium
    &\@m {
      @include grid-media($grid-md) {
        &-rs {
          padding-top: 0 !important;
        }

        &-1 {
          padding-top: 1%;
        }

        &-2 {
          padding-top: 2%;
        }

        &-3 {
          padding-top: 3%;
        }

        &-4 {
          padding-top: 4%;
        }

        &-5 {
          padding-top: 5%;
        }

        &-6 {
          padding-top: 6%;
        }

        &-7 {
          padding-top: 7%;
        }

        &-8 {
          padding-top: 8%;
        }

        &-9 {
          padding-top: 9%;
        }

        &-10 {
          padding-top: 10%;
        }
      }
    }
    // Small
    &\@s {
      @include grid-media($grid-sm) {
        &-rs {
          padding-top: 0 !important;
        }

        &-1 {
          padding-top: 1%;
        }

        &-2 {
          padding-top: 2%;
        }

        &-2 {
          padding-top: 3%;
        }

        &-4 {
          padding-top: 4%;
        }

        &-5 {
          padding-top: 5%;
        }

        &-6 {
          padding-top: 6%;
        }

        &-7 {
          padding-top: 7%;
        }

        &-8 {
          padding-top: 8%;
        }

        &-9 {
          padding-top: 9%;
        }

        &-10 {
          padding-top: 10%;
        }
      }
    }
  }
  /*
  ==============
  Padding Bottom
  ==============
  */
  &b {
    &-1 {
      padding-bottom: 1%;
    }

    &-2 {
      padding-bottom: 2%;
    }

    &-3 {
      padding-bottom: 3%;
    }

    &-4 {
      padding-bottom: 4%;
    }

    &-5 {
      padding-bottom: 5%;
    }

    &-6 {
      padding-bottom: 6%;
    }

    &-7 {
      padding-bottom: 7%;
    }

    &-8 {
      padding-bottom: 8%;
    }

    &-9 {
      padding-bottom: 9%;
    }

    &-10 {
      padding-bottom: 10%;
    }
    /*
    =============================
    Padding Bottom - Break Points
    =============================
    */
    // Medium
    &\@m {
      @include grid-media($grid-md) {
        &-rs {
          padding-bottom: 0 !important;
        }

        &-1 {
          padding-bottom: 1%;
        }

        &-2 {
          padding-bottom: 2%;
        }

        &-3 {
          padding-bottom: 3%;
        }

        &-4 {
          padding-bottom: 4%;
        }

        &-5 {
          padding-bottom: 5%;
        }

        &-6 {
          padding-bottom: 6%;
        }

        &-7 {
          padding-bottom: 7%;
        }

        &-8 {
          padding-bottom: 8%;
        }

        &-9 {
          padding-bottom: 9%;
        }

        &-10 {
          padding-bottom: 10%;
        }
      }
    }
    // Small
    &\@s {
      @include grid-media($grid-sm) {
        &-rs {
          padding-bottom: 0 !important;
        }

        &-1 {
          padding-bottom: 1%;
        }

        &-2 {
          padding-bottom: 2%;
        }

        &-3 {
          padding-bottom: 3%;
        }

        &-4 {
          padding-bottom: 4%;
        }

        &-5 {
          padding-bottom: 5%;
        }

        &-6 {
          padding-bottom: 6%;
        }

        &-7 {
          padding-bottom: 7%;
        }

        &-8 {
          padding-bottom: 8%;
        }

        &-9 {
          padding-bottom: 9%;
        }

        &-10 {
          padding-bottom: 10%;
        }
      }
    }
  }
  /*
  ===============
  Padding - Reset
  ===============
  */
  &-rs {
    @include grid-media($grid-md) {
      padding: 0 !important;
    }
    // Medium
    &\@m {
      @include grid-media($grid-md) {
        padding: 0 !important;
      }
    }
    // Small
    &\@s {
      @include grid-media($grid-sm) {
        padding: 0 !important;
      }
    }
  }
}