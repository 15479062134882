.border {
  border: solid 1px map($color-blue, extraLight) !important;

  // Top
  &-t {
    border-top: solid 1px map($color-blue, extraLight) !important;

    &-rs {
      border-top: none !important;
    }
  }

  // Bottom
  &-b {
    border-bottom: solid 1px map($color-blue, extraLight) !important;

    &-rs {
      border-bottom: none !important;
    }
  }

  // Right
  &-r {
    border-right: solid 1px map($color-blue, extraLight) !important;

    &-gray {
      &-extraDark {
        border-right: solid 1px map($color-gray, extraDark) !important;
      }
    }

    &-rs {
      border-right: none !important;
    }
  }

  // Left
  &-l {
    border-left: solid 1px map($color-blue, extraLight) !important;

    &-rs {
      border-left: none !important;
    }
  }

  // Color
  &-color {

    &-green {

      &-base {
        
        border-color: map($color-green, base) !important;
      }
    }

    &-blue {
      
      &-medium {
        
        border-color: map($color-blue, medium) !important;
      }

      &-sharpLight {
        
        border-color: map($color-blue, sharpLight) !important;
      }
    }

    &-gray {
      &-extraDark {
        border-color: map($color-gray, extraDark) !important;
      }
    }
  }

  // Width
  &-width {

    &-1 {
      border-width: 1px;
    }

    &-2 {
      border-width: 2px;
    }
  }

  &-rs {
    border: none !important;
  }

  // Breakpoint Medium
  &\@m {
    @include grid-media($grid-md) {
      // Reset
      &-rs {
        border: none !important;
      }
      // Bottom
      &-b {
        border-bottom: solid 1px map($color-blue, extraLight);

        &-rs {
          border-bottom: none !important;
        }
      }
      // Top
      &-t {
        border-top: solid 1px map($color-blue, extraLight);

        &-rs {
          border-top: none !important;
        }
      }
      // Right
      &-r {
        border-right: solid 1px map($color-blue, extraLight);

        &-rs {
          border-right: none !important;
        }
      }
      // Left
      &-l {
        border-left: solid 1px map($color-blue, extraLight);

        &-rs {
          border-left: none !important;
        }
      }
    }
  }

  // Breakpoint Small
  &\@s {
    @include grid-media($grid-sm) {
      // Reset
      &-rs {
        border: none !important;
      }
      // Bottom
      &-b {
        border-bottom: solid 1px map($color-blue, extraLight);

        &-rs {
          border-bottom: none !important;
        }
      }
      // Top
      &-t {
        border-top: solid 1px map($color-blue, extraLight);

        &-rs {
          border-top: none !important;
        }
      }
      // Right
      &-r {
        border-right: solid 1px map($color-blue, extraLight);

        &-rs {
          border-right: none !important;
        }
      }
      // Left
      &-l {
        border-left: solid 1px map($color-blue, extraLight);

        &-rs {
          border-left: none !important;
        }
      }
    }
  }
}