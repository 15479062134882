.button {
  $self: &;
  position: relative;
  display: inline-block;
  text-align: center;

  i {
    display: block;
    margin: 0 auto;
  }

  // Blocks
  &-group {
    $self: &;
    text-align: center;

    #{ $self }__text {
      display: block;
      margin: 15px 0 0 0;
      font-weight: bold;

      @include grid-media($grid-md) {
        font-size: map($font-size-button, \@m);
      }
    }

    &--inline {
      display: flex;
      align-items: center;

      #{ $self }__text:first-child {
        float: left;
        margin: 0 30px 0 0;
      }
  
      #{ $self }__text:last-child {
        float: right;
        margin: 0 0 0 30px;
      }
    }
  }

  // Elements
  &__text {
    padding: 0 15px 0 0;
  }

  &__icon-row {
    padding: 0 .65em;
    line-height: 3.5;
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    
    &--center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  // Modifiers
  &--small {
    width: 40px;
    height: 40px;
    background-color: map($color-gray, light);
    transition: background-color .5s ease;
  }

  &--active {
    width: 40px;
    height: 40px;
    background-color: map($color-white, base);
    border: 2px solid map($color-gray, light);
  }

  &--large {
    width: 80px;
    height: 80px;
    background: map($color-white, base);
  }

  &--round {
    border-radius: 50%;
  }

  &--inline {
    
    i {
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}