.modal {
  $self: &;
  position: relative;

  @include grid-media($grid-md) {

    h3 {
      font-size: map($font-size-h3, \@s);
      line-height: map($line-height-h3, \@s);
    }

    p {
      font-size: map($font-size-p, base);
      line-height: map($line-height-p, \@m);
    }

    a {
      font-size: map($font-size-a, base);
      line-height: map($line-height-a, base);
    }
  }

  &__close {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 36px;
    height: 36px;
    border: solid 1px map($color-white, base);
    border-radius: 50%;
    z-index: 999;
  
    &::after, &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1px;
      height: 24px;
      background-color: map($color-white, base);
    }
  
    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  
    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__image {

    @include grid-media($grid-md) {
      min-height: 400px;
    }

    @include grid-media($grid-sm) {
      min-height: 300px;
    }
  }

  &__content {
    position: relative;
    min-height: 550px;
    padding: 40px;
    background: map($color-blue, dark);

    @include grid-media($grid-md) {
      min-height: auto;
    }

    @include grid-media($grid-sm) {
      padding: 60px 30px 60px 30px;
    }

    h3, p, a {
      color: map($color-white, base);
    }

    h3 strong, a {
      color: map($color-blue, extraLight);
    }

    p {
      margin: 60px 0;

      @include grid-media($grid-md) {
        margin: 30px 0;
      }
    }

    a {
      &::after {
        top: 1px;
        left: 10px;
      }
    }

    #{ $self }__nav {
      margin: 60px 0 0 0;

      @include grid-media($grid-md) {
        margin: 0;
      }

      li {

        a {
          @extend a.strong;

          @include grid-media($grid-md) {
            font-size: map($font-size-a, extraSmall);
            line-height: map($line-height-a, extraSmall);
          }
        }
      }
    }
  }
}