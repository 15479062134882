.color {

  &-bg {

    &-blue {

      &-megaLight {
        background-color: map($color-blue, megaLight);
      }
      &-mediumDark {
        background-color: map($color-blue, mediumDark);
      }
    }

    &-gray {
      &-ultraLight {
        background-color: map($color-gray, ultraLight);
      }
      &-superLight {
        background-color: map($color-gray, superLight);
      }
    }

    &-white {
      background-color: #ffffff;
    }
  }

  &-blue {
    &-base {
      color: map($color-blue, base) !important;
    }
    &-baseMedium {
      color: map($color-blue, baseMedium);
    }
    &-dark {
      color: map($color-blue, dark);
    }
  }
}