.menu {
  $self: &;

  &__back {
    font-family: $sans-serif-bold;
    color: map($color-blue, base);
    font-size: map($font-size-p, small);
    text-transform: uppercase;
    padding-bottom: 1em;
    background: 0 0;
    border: none;
    display: none;
    @include grid-media($grid-md) {
      display: block;
    }
  }

  &__close-button {
    background: transparent;
    position: fixed;
    border: none;
    top: 80px;
    right: 80px;
  }

  &__close-button-mobile {
    background: 0 0;
    border: none;
  }

  &__sub-toggle{
    padding: .5em 0 0 .5em;

    > span, > button > span {
      &:hover {
        border-bottom: 1px solid map($color-blue, extraLight);
        color: map($color-blue, base);

        @include grid-media($grid-sm) {
          border-bottom: 0;
        }
        a {
          color: map($color-blue, base);
        }
      }

    }
    &.mobile-active {
      color: map($color-blue, base);

      ul {
        pointer-events: auto;
      }
    }
    &.is-active, &.current {
      color: map($color-blue, base);
      width: auto;

      span>a {
        color: map($color-blue, base);
      }

      &::before {
        width: 0;
        height: 0;
        border-top: 50px solid transparent;
        border-right: 100px solid red;
        border-bottom: 50px solid transparent;
      }

      > span {
        border-bottom: 1px solid map($color-blue, extraLight);
      }

    }
  }

  // Elements
  &--image {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    width: 85vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1999;
    transition: transform .4s ease;

    &.is-active {
      transform: translateX(0);
    }

    > a {
      position: relative;
      top: 60px;
      left: 80px;

      @include grid-media($grid-ml) {
        top: 30px;
        left: 40px;
      }
    }

    @include grid-media($grid-xxl) {
      width:75vw;
    }

    @include grid-media($grid-xlm){
      width: 65vw;
    }

    @include grid-media($grid-md){
      display: none;
    }
  }

  &__promo {
    position: absolute;
    right: 0;
    top: 17em;
    width: 568px;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out;
    -webkit-transition: opacity .3s ease-in-out;

    &--image {
      position: relative;
      width: inherit;
    }

    &--message {
      position: relative;
      color: map($color-white, base);
      font-family: $serif-bookItalic;
      font-size: map($font-size-p, medium);
      padding: 1em 10% 0 5%;
      text-align: right;
    }
  }

  &--nav {
    background-color: map($color-white, base);
    //height: 100vh;
    height: calc(100%);
    z-index: 2000;
    //overflow-y: auto;
    width: 15vw;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);
    box-shadow: 10px 10px 20px 10px transparent;
    transition: all .4s ease;

    &.is-active {
      transform: translateX(0);
      box-shadow: 10px 10px 20px 10px #888888;

      #{ $self }--sub {
        right: calc(100% + 40px);
      }
    }

    nav {
      padding: 5%;
    }

    @include grid-media($grid-xxl) {
      width: 25vw;
    }

    @include grid-media($grid-xlm){
      width: 35vw;
    }

    @include grid-media($grid-md){
      width: 100vw;
      box-shadow: none;
    }
  }

  &--main {
    opacity: 1;
    transition: opacity 0.3s linear;
    transition-delay: 1.3s;
    height: 100%;
    width: 100%;
    overflow: visible;

    @include grid-media($grid-sm) {
      padding-bottom: 7em;
    }

    li {
      cursor: pointer;
      opacity: 0;
      margin-left: 50px;
    }

    a, button {
      font-family: $serif-bold;
      font-size: map($font-size-h5, base);
      color: map($color-blue, dark);
      line-height: 1.4;
    }

    button {
      background: transparent;
      border: none;
    }

    &.is-active {
      opacity:1;
    }

    .sub-menu-toggle {
      padding: .5em 0;
    }

    @include grid-media($grid-md) {
      width: 80%;
      overflow: scroll;
    }
    @include grid-media($grid-sm) {
      min-height: 100%;
      width: 100%;
    }
  }

  &--items {
    position: absolute;
    top: 12em;
    left: 2em;
    z-index: 2000;
    width: 100%;

    > ul {
      font-family: $serif-bold;
      font-size: map($font-size-h5, base);
      color: map($color-blue, dark);
    }

    @include grid-media($grid-md) {
      top: 9em;
    }

    @include grid-media($grid-sm) {
      position: static;
      height: 100%;
      padding: 7em 0 0 2em;
    }

    // Fix for landscape scrolling
    @media (orientation: landscape) and (max-height: 26em) and (min-aspect-ratio: 5/3){
      position: static;
      height: 100%;
      padding: 4em 0 0 2em;
    }
  }

  &--sub {
    text-transform: uppercase;
    position: absolute;
    top: -10em;
    opacity: 0;
    transition: all .3s ease-out;
    max-height: 95vh;
    overflow-y: scroll;
    -ms-overflow-style: none;  // IE 10+
    overflow: -moz-scrollbars-none;  // Firefox
    pointer-events: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;  // Safari and Chrome
    }

    @include grid-media($grid-md){
      position:relative;
      top: auto;
      right: auto;
      left: 0;
      opacity: 1;
    }

    li {
      font-family: $sans-serif-bold;
      color: map($color-white, base);
      opacity: 1;
      margin: 0 5px;
      line-height: 1.4;

      @include grid-media($grid-md) {
        color: map($color-blue, base);
      }
    }

    &.current {
      opacity: 1!important;
      z-index: 3000;
      pointer-events: auto !important;
    }
  }

  &--mobile-icon {
    width:20%;

    @include grid-media($grid-md) {
      padding:15px;
    }

    @include grid-media($grid-sm) {
      padding:5px;
    }

    @include grid-media($grid-xs) {
      padding:0;
    }
  }

  &-button {
    border: none;
    background-color: transparent;
  }

  &__item {
    display: inline-block;
    color: map($color-blue, base);
    font-size: em(map($font-size-p, mediumSmall));
  }

  &__sub-items {
    padding: 0.5em 3em 0.5em 0;
    border-bottom-style: solid;
    border-bottom-width: .5px;
    border-color: rgba(255,255,255,.5);
    font-family: $sans-serif-bold;
    color: map($color-white, base);
    font-size: map($font-size-p, medium);
    opacity: 1;
    margin: 0 5px;
    width: 500px;
    line-height: 1.4;
    text-align: right;
    background-color: #767676;

    &.current {
      & >a {
        color: map($color-blue, light);
      }
    }

    .arrow {
      &.current {
        color: #afd4ee!important;
      }
      &.current:after {
        transform: rotate(-90deg);
        color: #afd4ee;
      }

      @include grid-media($grid-md) {
        &.current, &.current:after {
          color: #034EA1!important;
        }
      }
    }


    &.safari {
      border-color: map($color-gray, light);

      @include grid-media($grid-md) {
      border-color: map($color-blue, light);
      }
    }

    &:first-child {
      margin-top: 13.5em;
      border-top-style: solid;
      border-top-width: .5px;

      @include grid-media($grid-md) {
        margin-top: 0px;
      }
    }

    > a, > button {
      font-family: $sans-serif-bold;
      color: map($color-white, base);
      font-size: map($font-size-p, medium);
      text-transform: uppercase;

      @include grid-media($grid-md) {
        color: map($color-blue, base);
        font-size: map($font-size-p, mediumSmall);
      }
    }

    @include grid-media($grid-md) {
      width: auto;
      text-align: left;
      border-color: map($color-blue, light);
      padding: 1em 0 1em 0;
      font-size: map($font-size-p, mediumSmall);
    }
  }

  &__bottom{
    max-height: 0;
    overflow-y: hidden;
    width: 100%;
    transition: all .75s ease;

    li.current {
      a{
        color: map($color-blue, extraLight);
      }
      &:first-child {
        border-top: .5px solid rgba(255,255,255,.5);
        margin-top: .5em;
      }
      @include grid-media($grid-md) {
        a{
          color: map($color-blue, base);
          font-family: $serif-bold;
        }
      }
    }

    li>a {
      font-family: $serif-book;
      text-transform: none;
      padding: 0.25em 0 0.25em 0;
      border: none;
      margin: 0;
      position: relative;
      font-size: map($font-size-p, medium);
      color: map($color-white, base);

      @include grid-media($grid-md) {
        color: map($color-blue, base);
        font-size: map($font-size-p, mediumSmall);
      }

    }

    li:first-child {
      padding-top: 1.5em;
    }

    li:last-child {
      padding-bottom: 1.5em;
    }
  }

  .share-fade {

    @include grid-media($grid-md) {
      display: none;
      margin-right: 45px !important;
    }
    @include grid-media($grid-sm) {
      width: 50px
    }
  }

  &__search-btn, &__share-btn {
    position: relative;
    border: 2px solid #acacac;
    cursor: pointer;

    &:before {
      content: '';
      display: none;
      width: 2px;
      height: 19px;
      background: map($color-gray, light);
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    &.button--active {

      &:before {
        display: block;
      }
    }
  }

  .share-show {
    position: relative;
    display: inline-block;
  }

  &__share, &__share-open {
    position: absolute;
    top: 3.5em;
    left: -5em;
    background-color: map($color-white, base);
    border-top: 1px solid map($color-gray, extraLight);
    height: 52px;
    width: auto;
    opacity: 0;
    transition: opacity .5s ease;
    pointer-events: none;

    @include grid-media($grid-md) {
      top: 4.5em;
    }

    @include grid-media($grid-sm) {
      top: 4em;
    }

    @include grid-media($grid-xs) {
      top: 3.5em;
    }

    .icon::before {
      color: map($color-gray, light);
    }
  }

  &__search, &__search-open {
    position: absolute;
    top: 8em;
    right: 3.2em;
    background-color: map($color-gray, superLight);
    border-top: 1px solid map($color-gray, light);
    height: 40px;
    width: 300px;
    opacity: 0;
    transition: opacity .5s ease;
    pointer-events: none;

    @include grid-media($grid-ml) {
      top: 6.2em;
      right: 1em;
      width: 300px;
    }

    @include grid-media($grid-md) {
      top: 6.5em;
      right: 4.125em;
    }

    @include grid-media($grid-sm) {
      top: 5.8em;
      right: 3.4em;
    }

    @include grid-media($grid-xs) {
      top: 5.5em;
      right: .5em;
    }
    div {
      display: inline-block;
    }

    .search-box {
      border:none;
      background-image:none;
      background-color:transparent;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      font-family: $serif-book;
      color: map($color-gray, base);
      font-size: map($font-size-p, base);
      padding: .7em 0 .7em 1em;
      width: 300px;
      display: inline-block;

      @include grid-media($grid-ml) {
        width: 250px;
      }
    }

    input[type="text"] {
      border:none;
      background-image:none;
      background-color:transparent;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      font-family: $serif-book;
      color: map($color-blue, base);
      font-size: map($font-size-p, base);
      padding: .5em 0 .5em 1em;
      width: 300px;
      display: inline-block;

      &::-ms-clear {
        display: none;
      }

      @include grid-media($grid-ml) {
        width: 250px;
      }
    }

    button {
      background-color: map($color-gray, light);
      font-size: map($font-size-p, base);
      border: 0;
      display: inline-block;
      border: solid map($color-gray, light);
      border-width: 4px 6px 4px 7px;
      border-radius: 5px;
      font: normal normal normal 14px/1 Font Awesome\ 6 pro;
      font-size: 1em;
      font-weight: 700;
      color: map($color-white, base);
      transform: translateX(-50%);
      cursor: pointer;
      position: absolute;

      span {
        display: none;
      }
    }

    button.search-button {
      content: "";
        top: 7px;
        right: 20px;

      &::before {
        content: "\f054";
      }
    }

    button.search-close-button {
      content: "";
      top: 7px;
      right: -10px;

      &::before {
        content: "\f00d";
      }
    }
  }

  &__search-open, &__share-open {
    top: 200px;
    left: 30px;

    @include grid-media($grid-sm) {
      left: 18px;
    }
  }

  // Modifiers
  &--box {
    transition: all .3s ease;
  }
  &--header {
    position: relative;
    display: flex;
    align-items: center;
    top: 12px;
    z-index: 3000;

    @include grid-media($grid-md) {
      top:0;
    }

    #{ $self }__item {
      margin: 0 45px 0 0;

      @include grid-media($grid-md) {
        margin: 0;
      }

      .icon-share, .icon-search {

        &::before {
          color: map($color-white, base);
        }
      }

      .icon-active {
        &::before{
          color: map($color-gray, light);
        }
      }

      &.menu-end {
        margin: 0;
      }
    }
  }

  &--footer {
    margin: 5px 0 20px 0;

    @include grid-media($grid-ml) {
      margin: 60px 0 0 0;
    }

    @include grid-media($grid-md) {
      margin: 30px 0 0 0;
    }

    #{ $self }__item {
      margin: 0 10px 0 0;

      &:nth-last-child(2) {
        @extend .m\@m-rs;
      }

      &:last-child {
        margin: 0;
        position: relative;
        color: map($color-gray, base);
        @extend .icon-arrow-up;
        @extend .hide\@m;
      }
    }
  }

  &--quick {
    display: flex;
    justify-content: center;
    padding: 0 !important;
    background-color: map($color-blue, mediumDark);
    text-align: center;

    @include grid-media($grid-md) {
      display: block;
    }

    &>div{
      display:flex;
      padding: 40px 0;

      @include grid-media($grid-md) {
        display: block;
      }
    }

    &>div>div {
      &:last-child {
        margin: 0;
      }

      &:hover {
        color: map($color-green, medium);
      }

      &:not(:first-child) .menu__item:after {
        content: '|';
        position: absolute;
        top: 0;
        left: -2.5px;
        color: map($color-white, base);
      }

      @include grid-media($grid-md) {

        &:last-child .menu__item{
          margin: 0 !important;
        }

        &:not(:first-child) .menu__item:after {
          content: none;
        }

      }
    }

    #{ $self }__item {
      position: relative;
      padding: 0 24px;
      font-family: $sans-serif-bold;
      letter-spacing: 1px;
      color: map($color-white, base);
      transition: color .3s ease;
      text-transform: uppercase;

      &:hover {
        color: map($color-green, medium);
      }

      &:last-child {
        margin: 0;
      }

      &:not(:first-child) {
        &::after {
          content: '|';
          position: absolute;
          top: 0;
          left: -2.5px;
          color: map($color-white, base);
        }
      }

      &-info {
        font-size: em(map($font-size-p, base));

        &:hover {
          color: map($color-white, base);
        }
      }

      .details {
        font-size: .8rem;
        color: #E0E2E6;
      }

      @include grid-media($grid-md) {
        display: block;
        margin: 30px 0;

        &:first-child {
          margin: 0 0 30px 0;
        }

        &:not(:first-child) {
          &::after {
            content: none;
          }
        }
      }
    }
  }

  &--quick-links-block {
    padding: 20px 0!important;
  }

  &--list {
    padding: 0 20px 40px 20px;
    border-left: solid 2px map($color-green, base);

    #{ $self }__item {
      @extend .h4;
      @extend .link;
      display: block;
      margin-top: 5px;
      font-family: $serif-medium;
      font-size: rem(map($font-size-p, large));
      line-height: map($line-height-p, extraLarge);
      transition: color .3s ease;

      &::after {
        left: 10px;
      }

      &:hover {
        color: map($color-green, base) !important;
      }
    }

    @include grid-media($grid-md) {
      padding: 0;
      border-left: none;

      #{ $self }__item {
        margin: 20px 0 0 0;
        font-size: rem(map($font-size-a, medium));
        line-height: map($line-height-a, medium);
      }
    }
  }

  &--article {
    margin-top: 30px;
    @extend .tc-2;

    #{ $self }__item {
      display: block;
      @extend .h4;
      @extend .link;
      color: map($color-blue, medium);
      transition: color .3s ease;

      &::after {
        left: 10px;
      }

      &:hover {
        color: map($color-green, base);
      }
    }

    @include grid-media($grid-md) {

      #{ $self }__item {
        font-size: rem(map($font-size-a, medium));
        line-height: map($line-height-a, medium);
      }
    }
  }
}

::placeholder {
  color: map($color-gray, base);
  font-family: $serif-bookItalic;
}

:-ms-input-placeholder {
  color: map($color-blue, extraLight) !important;
  font-family: $serif-bookItalic !important;
}
