div.matrix {
  $self: &;

  p {
    @extend .body;
    @extend .body-medium;
  }

  // Modifiers
  &--default {
  
    hr {
      border-color: map($color-gray, extraDark);
      @extend .mb-5;
    }

    .formatted-title {
      
      p, h3 {
        color: map($color-blue, dark);
      }

      p {
        @extend p.mediumLarge;
        margin-bottom: 20px;
        font-family: $sans-serif-light;
        text-transform: uppercase;
      }

      h3 {
        @extend h3.mediumSmall;
        @extend h3\@m-small;
      }

      table {
        width: 100%;
        margin-bottom: -20px;
        padding: 60px;
        background-color: map($color-blue, medium);

        @include grid-media($grid-md) {
          padding: 5%;
          margin-bottom: 0;
        }

        tr {
          display: block;
          @extend .grid;
          @extend .grid-3;
          @extend .grid\@m;
          @extend .grid-mw--1080;
        }

        td {
          @extend .col;
          @extend .tac;
          padding: 20px;
          border: none;

          h3 {
            @extend h3.mediumSmall;
            @extend h3\@m-small;
            display: inline-block;
            position: relative;
            color: map($color-white, base);

            &::after {
              content: '';
              position: absolute;
              top: 100%;
              left: 0;
              width: 100%;
              height: 2px;
              margin-top: 8px;
              background-color: map($color-green, base);
            }
          }
        }
      }
    }

    #{ $self }__lockup {
      margin: 60px auto;

      & + .matrix__rule {
        margin: 60px auto 0 auto;
      }
    }

    #{ $self }__rule {
      @extend .mv-5;
    }

    #{ $self }__body {

      h1, h2, h3, h4, h5, h6, p {
        color: map($color-blue, dark);
      }

      h1 {
        @extend h1\@m-medium;

        @include grid-media($grid-xs) {
          font-size: map($font-size-h1, \@s);
        }
      }

      h2 {
        @extend h2.large;
        @extend h2\@m-small;

        strong {
          color: map($color-blue, base);
        }
      }

      h3 {
        @extend h3.mediumSmall;
        @extend h3\@m-small;

        strong {
          color: map($color-blue, base);
        }
      }

      p {
        margin: 25px 0;

        &:first-of-type {
          margin: 50px 0 25px 0;
        }

        @include grid-media($grid-md) {
          margin: 5% 0;

          &:first-of-type {
            margin: 5% 0;
          }
        }

        strong {
          @extend p.body.extraStrong;
        }
      }
      .typefaces {
        margin: 0 auto;
        background-color: white;
        color: blue;
        padding-top: 3%;
        width: 70%;

        tbody {
          
          tr {
            td {
              padding: 0 30px;
              background-color: white;
              color: map($color-blue, base);
              line-height: 1.25;
              font-size: 1.25rem;
              @include grid-media($grid-md) {
                display: block;
              }
            }
          }
        }
      }
      .type-dark {
        width: 80%;
        tbody {
          tr {
            td {
              color: map($color-blue, medium);
              @extend.tal
            }
          }
        }
      }
      
      .tints {
        width: 100%;
        font-family: $sans-serif-light;
        

        td {
          font-size: map($font-size-p, mediumLarge);
          padding: 40px 30px;
          &:not(:first-of-type) {
            border-left: 30px solid white;
          }
          &:nth-of-type(2) {
            opacity: 0.75;
          }
          &:nth-of-type(3) {
            opacity: 0.5;
          }
          &:nth-of-type(4) {
            opacity: 0.25;
          }

          @include grid-media($grid-ms) {
            display: block;
            border-left: 0 !important;
            border-top: 10px solid white;
          }
        }
      }

      table {
        margin: 50px 0 25px 0;
        border: none;
        text-align: center;

        @include grid-media($grid-md) {
          margin: 25px 0;
        }

        tr {
          border: none;

          &:not(:last-of-type) {
            border-bottom: solid 1px map($color-white, base);
          }
        }

        td {
          @extend h5.medium;
          @extend h5\@m-small;
          padding: 30px;
          color: map($color-white, base);
          background-color: map($color-blue, base);
          border: none;

          @include grid-media($grid-md) {
            padding: 10px;
          }

          @include grid-media($grid-xs) {
            font-size: map($font-size-p, small);
          }
          
          &:not(:last-of-type) {
            border-right: solid 1px map($color-white, base);
          }
        }
      }
    }

    #{ $self }__image {
      @extend .tac;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px 0;

      &-wrapper {
        @extend .pv-1;
      }

      figure {
        display: table;

        figcaption {
          @extend p.caption;
          display: table-caption; 
          caption-side: bottom ;
          margin-top: 40px;
          color: map($color-blue, dark);
        }
      }
    }

    #{ $self }__attributes {

      .grid {
        
        &:not(:last-of-type) {
          border-bottom: solid 1px map($color-blue, dark);
        }
      }

      @include grid-media($grid-md) {
        .grid {

          &:not(:last-of-type) {
            border-bottom: none;
          }
        }
      }

      .attribute {
        transition: all .3s ease;

        &:not(:last-of-type) {
          border-right: solid 1px map($color-blue, dark);
        }

        @include grid-media($grid-md) {
          &:not(:last-of-type) {
            border-right: none;
          }
        }

        @include grid-media($grid-md-min) {
          cursor: pointer;

          &:hover {
            
            .attribute__wrapper {
              border-radius: 8px;
            }

            .attribute__title {
              margin-top: 0;

              &::after {
                opacity: 1;
                transform: scaleX(1);
              }
            }

            .attribute__body {
              opacity: 1;
              transition-delay: .15s;
              transition: all .3s ease;
            }
          }
        }

        &__wrapper {
          position: relative;
          width: 90%;
          margin: 10px;
          padding-top: 90%;
          border-radius: 50%;
          transition: all .3s ease;

          @include grid-media($grid-md) {
            min-height: 260px;
            width: auto;
            padding-top: 0;
            border-radius: 0;
          }

          &.collaborative {
            background-color: map($color-blue, dark);
            p {
              color: map($color-white, base);
            }
          }

          &.idealistic {
            background-color: map($color-blue, base);
            p {
              color: map($color-white, base);
            }
          }

          &.diverse {
            background-color: map($color-gray, diverse);
            p {
              color: map($color-white, base);
            }
          }

          &.inquisitive {
            background-color: map($color-blue, inquisitive);
          }

          &.independent {
            background-color: map($color-blue, independent);
          }

          &.driven {
            background-color: map($color-green, driven);
          }

          &.exploratory {
            background-color: map($color-purple, base);
          }

          &.passionate {
            background-color: map($color-blue, passionate);
          }

          p {
            color: map($color-gray, superDark);
          }
        }

        &__content {
          @extend .pav;
        }

        &__title {
          @extend p.large;
          display: inline-block;
          position: relative;
          margin-top: 56%;
          margin-bottom: 8px;
          transition: all .3s ease;

          @include grid-media($grid-md) {
            margin-top: 0;
          }

          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            border: solid 1px map($color-white, base);
            opacity: 0;
            transform: scaleX(0);
            transition: all .3s ease;
          }
        }

        &__body {
          @extend p.mediumSmall;
          padding: 0 8px;
          transition: all 0s ease;
          transition-delay: 0s;
          opacity: 0;

          @include grid-media($grid-md) {
            opacity: 1;
          }
        }
      }
    }

    #{ $self }__graphs {

      .grid {
        @extend .flex;
        @extend .block\@m;

        &:not(:last-of-type) {
          
          & > .col {
            padding-bottom: 10%;
          }
        }
      }

      .graph {
        flex: 1;

        &:not(:last-of-type) {
          border-right: solid 1px map($color-blue, dark);

          @include grid-media($grid-md) {
            border-right: none;
          }
        }

        &__content {

          p {
            @extend p.base;
            @extend p.base-tall;
            font-family: $sans-serif-book;
            margin: 25px 0;

            strong {
              @extend p.extraLarge;
              font-family: $serif-extraBold;
              color: map($color-blue, base);
            }
          }
        }
      }
    }

    #{ $self }__table {

      &:not(.col-separator) {

        @include grid-media($grid-md) {

          td {
            display:block; 
            box-sizing:border-box; 
            clear:both;

            &:last-of-type {
              padding: 0 20px 20px 20px;
            }
          }
        }

        td {

          h4, p {
            @extend .tac\@m;
          }

          h4 {
            @extend .tar;
          }

          p {
            @extend .taj;
          }
        }
      }

      &.col-separator {

        td {

          &:not(:last-of-type) {
            border-right: solid 1px map($color-blue, base);
          }
        }
      }

      table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
      }

      tr {
        border-bottom: solid 1px map($color-blue, base);

        &:first-of-type {
          border-top: solid 1px map($color-blue, base);
        }
      }

      td {
        padding: 20px;

        @include grid-media($grid-md-min) {
          &:first-of-type {
            padding: 50px 50px 50px 100px;
          }

          &:last-of-type {
            padding: 50px 100px 50px 50px;
          }
        }

        h4 {
          @extend .tac;
          @extend h4.mediumSmall;
          @extend h4\@m-extraSmall;
          color: map($color-blue, dark);

          @include grid-media($grid-xs) {
            font-size: map($font-size-h4, \@s);
          }
        }
      }
    }

    #{ $self }__image-block-grid {

      & + .matrix__attributes {
        margin-top: 0;
      }

      .grid {

        @include grid-media($grid-md) {

          &:first-of-type {

            .image-block-wrapper {

              &:first-of-type {
                border-top: solid 1px map($color-blue, sharpLight);
              }
            }
          }
        }  

        &:nth-of-type(odd) {

          .image-block-wrapper {

            &:nth-of-type(2) { 
              
              .image-block__background {
                @extend .flex\@m;
                @extend .flex\@m-order;
                @extend .flex\@m-order-1;
              }
    
              .image-block__image {
                @extend .flex\@m;
                @extend .flex\@m-order;
                @extend .flex\@m-order-2;
                border-right: none;
                border-left: solid 1px map($color-blue, sharpLight);
              }
            }
          }
        }

        &:nth-of-type(even) {
          
          .image-block-wrapper {

            &:nth-of-type(2) { 
              
              .image-block__background {
                @extend .flex\@m;
                @extend .flex\@m-order;
                @extend .flex\@m-order-2;
              }
    
              .image-block__image {
                @extend .flex\@m;
                @extend .flex\@m-order;
                @extend .flex\@m-order-1;
                border-left: none;
                border-right: solid 1px map($color-blue, sharpLight);
              }
            }
          }
        }
      }

      .image-block-wrapper {
        overflow: hidden;
        border: solid 1px map($color-blue, sharpLight);
        border-left: none;

        &:last-of-type {
          border-right: none;
        }

        @include grid-media($grid-md) {
          border-top: none;
        }
      }

      .image-block {
        display: flex;
        
        .col {
          flex: 1;
          background-color: map($color-blue, medium);
        }

        &__image {
          max-height: 350px;

          &:first-of-type {
            border-right: solid 1px map($color-blue, sharpLight);
          }

          &:last-of-type {
            border-left: solid 1px map($color-blue, sharpLight);
          }
        }

        &__background {
          position: relative;
          @extend .tac;
      
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            //box-shadow: inset 0 40px 40px rgba(#000, 0.25);
          }
        }

        &__title {
          @extend .pac;
          @extend .tac;
          width: 100%;
          padding: 0 5%;
          
          p {
            @extend p.mediumSmall;
            @extend p\@ml-small;
            font-family: $sans-serif-book;
            color: map($color-green, base);
            text-transform: uppercase;
          }

          h5 {
            @extend h5.medium;
            @extend h5\@l-small;
            @extend h5\@ml-small;
            color: map($color-white, base);
          }
        }
      }
    }

    #{ $self }__color-selector {
      @extend .grid;
      @extend .grid-mw--1080;

      .color-selector {
        @extend .mv-5;
        position: relative;

        &.js-color-selector {

          &.active {

            .color-selector__caption {
              display: none;
            }

            .js-color-selector-close {
              display: block;
            }
          }
        }

        .js-color-swatches {
          
          &.active {
            @include grid-container;
            display: block;
            width: max-content;
            
            & > .col {
              float: left;
              flex: none;
            }
            
            @include grid-media($grid-sm) {
              max-width: 272px;
            }
          }
        }

        .js-color-swatch {
          transform: scale(1);
          transition: transform .2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

          &:hover, &.active {
            transform: scale(1.2);
          }
        }

        .js-color-slider-toggle {
          height: 0px; 
          opacity: 0;
          overflow-y: hidden;
          overflow-x: hidden;
        }

        .js-color-slider {
          @extend .mh\@ml-5;
        }

        .slick-slide {
          outline: none;
        }

        &__wrapper {
          position: relative;
        }

        &__swatches {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin: 0 auto 5% auto;

          @include grid-media($grid-md) {
            width: 90%;
          }

          & > .col {
            flex: 0 1 10%;
            margin: 10px;
            min-height: 34px;
            min-width: 34px;
            max-width: 120px;
            max-height: 120px;

            @include grid-media($grid-md) {
              flex: 0 1 12.5%;
              margin: 0;
              border: solid 5px transparent;
            }
          }
        }

        &__swatch {
          display: block;
          width: 100%;
          padding-top: 100%;
          border-radius: 50%;
          cursor: pointer;
        }

        &__caption {
          @extend .tac;
          display: block;
          transition: all .25s ease;

          p, p a {
            text-transform: uppercase;
            color: map($color-blue, dark);
          }

          p {
            @extend p.mediumSmall;
            font-family: $sans-serif-light;

            a {
              font-family: $sans-serif-book;
            }
          }
        }

        &__close {
          @extend .icon;
          @extend .icon-close;
          display: none;
          position: absolute;
          right: 0;
          top: 0 !important;
          margin-right: 5%;
          z-index: 9999;
        }
      }

      .slide-content {
        
        &__title {
          font-size: rem(map($font-size-p, medium));
          line-height: map($line-height-p, medium);
          font-family: $serif-medium;
          color: map($color-blue, dark);
          margin-bottom: 40px;
          
          @include grid-media($grid-md) {
            margin-bottom: 20px;
          }

          @include grid-media($grid-ms) {
            text-align: center;
            margin-top: 20px;
          }
        }
      }

      .color {

        &__swatch {
          width: 250px;
          height: 250px;
          border-radius: 50%;

          @include grid-media($grid-md) {
            width: 200px;
            height: 200px;
          }

          @include grid-media($grid-ms) {
            width: 150px;
            height: 150px;
            margin: 0 auto;
          }
        }
      }

      .color-details {

        @include grid-media($grid-ms) {
          text-align: center;
        }

        &__table {

          tr {

            &.hex {
              
              td {
                padding-top: 25px;
              }
            }
          }

          td {
            @extend p.base;
            @extend p\@m-mediumSmall;
            font-family: $sans-serif-book;
            color: map($color-blue, dark);

            &:first-child {
              font-family: $sans-serif-bold;
            }
          }
        }
      }

      .color-pairing {

        @include grid-media($grid-ms) {
          text-align: center;
        }

        &__title {
          @extend p.base;
          font-family: $sans-serif-book;
          color: map($color-blue, dark);
        }

        &__groups {
          margin: 10px 0;
        }

        &__group {
          display: inline-block;
          
          &:not(:last-of-type) {
            margin-right: 15px;
          }
        }

        &__swatch {
          display: inline-block;
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }

        &__plus {
          display: inline-block;
          position: relative;
          top: -8px;
          font-weight: bold;
          color: map($color-blue, dark);
        }
      }
    }

    .matrix__image-wrapper {
      padding: 20px 0;

      &:first-of-type {
        padding: 80px 0 20px 0;
      }

      &:last-of-type {
        padding: 20px 0 80px 0;
      }

      @include grid-media($grid-md) {
        padding: 5% 0;

        &:first-of-type {
          padding: 40px 0 5% 0;
        }
  
        &:last-of-type {
          padding: 5% 0 40px 0;
        }
      }
      
      .matrix__image {
        margin: 0;
      }
    }
  }

  .color-detail-wrapper {
    display: flex;
    justify-content: center;

    @include grid-media($grid-ms) {
      display: block;
    }

    .color-col{
      max-width: 300px;
      margin: 0 70px;

      @include grid-media($grid-ms) {
        margin: 0 auto;
      }
    }
    .color-table {
      max-width: 300px;
      color: map($color-blue, dark);

      p {
        padding: 20px 0;
        font-family: $serif-extraBold;
        font-size: map($font-size-p, mediumSmall);
      }
      table {
        font-family: $sans-serif-light;
        width: 300px;
        font-size: map($font-size-p, base);
      }
    }
    .color-swatch {
      height: 300px;
      width: 300px;
      border-radius: 50%;
      display: inline-block;
    }
  }
}