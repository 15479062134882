.overlay {
  $self: &;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background .3s ease;

  // Elements
  &__content {
    position: absolute;
    padding: 0 60px;

    @include grid-media($grid-md) {
      padding: 0;
    }

    &--left {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &--right {
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      h3 {
        text-align: right;
      }
    }

    &--center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      overflow: hidden;

      h1, h2, h3, h4, h5, p {
        text-align: center;
      }

      img {
        display: block;
        margin: 0 auto;
      }
    }

    &--centerRight {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      h1 {
        text-align: right;
      }
    }

    &--bottom {
      left: 0;
      bottom: 0;
      width: 100%;
    }

    &--bottomLeft {
      left: 0;
      bottom: 0;
      width: 100%;
      background: rgba(15,15,25,.6);
    }

    h1, h2, h3, h4, h5, p, a, span {
      color: map($color-white, base);
    }

    h3 {
      color: map($color-blue, ultraLight);

      strong {
        color: map($color-white, base);

        &:nth-of-type(2) {
          color: map($color-blue, ultraLight);
        }
      }

      & + p {
        margin-top: 16px;
      }
    }
  }

  // Modifiers
  &--animate {

    &:hover {
      background: transparent;

      &::before {
        opacity: 0;
      }

      #{ $self }__content {
        background: rgba(15,15,25,.6);
      }
    }
  }

  &--light {
    background: rgba(0,0,0,0.1);

    &:hover {
      background: none;
    }
  }

  &--mediumDark {
    background: rgba(map($color-blue, medium), 0.75);
  }

  &--dark {
    background: rgba(map($color-blue, dark), 0.6);
  }

  &--ultraDark {
    background: rgba(map($color-blue, ultraDark), 0.6);
  }

  &--extraDark {
    background: rgba(map($color-blue, extraDark), 0.5);
  }
  &--gradient {

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, rgba(map($color-blue, dark), 0.6), transparent);
      background-blend-mode: multiply;
      opacity: 1;
      transition: opacity .3s ease;
    }
  }
}


