.sidebar {

  // Modifiers
  &--news {
    padding: 80px 0;

    @include grid-media($grid-md) {
      padding: 20px 5%;
    }
  }
}