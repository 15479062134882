.accordion {
  $self: &;
  position: relative;

  // Modifiers
  &--news {

    #{ $self }__block {
      position: relative;
     
      &.is-active {
  
        #{ $self }__title {
          color: map($color-blue, base);
        }
  
        #{ $self }__button {
          border: solid 1px map($color-green, base);
  
          &::after, &::before {
            background-color: map($color-green, base);
          }

          &::after {
            transform: rotate(45deg) translate(-50%, -50%);
          }

          &::before {
            transform: rotate(-45deg) translate(-50%, -50%);
          }
        }
        
        #{ $self }__content {
          @extend .border-b;
        }
      }
    }

    #{ $self }__button {
      @extend .icon-plus;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      
      &::after, &::before {
        transform: rotate(0deg) translate(calc(-50%), -50%);
        transition: all .3s ease;
      }
    }
  
    #{ $self }__title {
      @extend .border-b;
      position: relative;
      padding: 25px 0;
      color: map($color-blue, dark);

      @include grid-media($grid-md) {
        padding: 15px 0;
      }
    }
  
    #{ $self }__content {
      display: none;
      padding: 25px 0;
    }
  }

  &--list {

    h4 {
      @extend .title;
      @extend .small;
      @extend h4\@m-extraSmall;
    }

    &>h4 {
      @include grid-media($grid-md){
        padding: 10px 0;
      }
    }


    h3 {
      @extend .title;
      @extend h3\@m-small;
      font-family: $serif-medium;
    }
    
    #{ $self }__block {
      
      @include grid-media($grid-md) {
        display: none;
      }

      &.is-active {
        
        & + #{ $self }__button {
          background-color: map($color-blue, base);

          &::before, &::after {
            background-color: map($color-blue, extraLight);
          }

          &::before {
            transform: rotate(45deg) translate(-50%, -50%);
          }

          &::after {
            transform: rotate(-45deg) translate(-50%, -50%);
          }
        }
      }
    }

    #{ $self }__button {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 100%;
      background-color: map($color-blue, extraLight);
      border-bottom: solid 1px map($color-white, base);
      transition: all .3s ease;

      &::before, &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: 0 0;
        width: 15px;
        height: 2px;
        background-color: map($color-white, base);
        transition: all .3s ease;
      }

      &::before {
        transform: rotate(90deg) translate(-50%, -50%);
      }

      @include grid-media($grid-md) {
        display: block;
      }
    }
  }
}