.article {
  $self: &;
  position: relative;

  &-teaser {
    position: static;
  }

  // Blocks
  &-overlay {
    @extend .overlay;

    &--dark {
      background: rgba(map($color-blue, dark), 0.5);
    }

    &__content {
      @extend .overlay__content;
      padding: 0 0 20px 20px;

      p, h5 {
        color: map($color-blue, superLight);
      }
    }
  }

  // Elements

  &-h3-title {
    margin-bottom: 1em;
    font-size: 2rem;
  }

  &__main-image {
    text-align: center;
    padding: 2em;

    img {
      width: 75%;
      height: auto;
    }
  }

  &__separator {
    text-align:center;
    padding: 5% 0;
    box-shadow: 0 1em 1em -1em rgba(0, 0, 0, .25);
  }

  &-link {
    line-height: 1.5;
    text-transform: uppercase;
  }

  &-title.title {
    @include grid-media($grid-md) {
      padding-top: 30px;
    }
  }
  &__banner {
    padding: 30px 0;
    @extend .border-t;
  }

  &__info {
    @extend .border-b;
  }

  &__content {
    @include grid-container;

    &--has-sidebar {

      #{ $self }__body {
        position: relative;
        float: left;
        top: -8px;
        width: 60%;
        padding: 0 45px 0 0;
        @extend .border\@m-b;

        &--extended {
          display: none;
          top: 0;
          width: 100%;
          padding: 0;
          margin-top: 2em;

          p {
            @extend .body;
            @extend .body-large;
            @extend .body\@m-medium;
          }
        }
      }

      #{ $self }__sidebar {
        float: left;
        width: 40%;
        border-left: solid 2px map($color-blue, dark);
        @extend .border\@m-l-rs;

        p {
          position: relative;
          top: -8px;
          padding: 0 0 0 15px;
          @extend .p\@m-rs;
        }
      }

      @include grid-media($grid-md) {
        #{ $self }__body, #{ $self }__sidebar {
          float: none;
          width: 100%;
        }

        #{ $self }__body {
          padding: 0 5%;
        }

        #{ $self }__sidebar {
          padding: 5% 10% 0 5%;
        }
      }
    }
  }

  #{ $self }__breadcrumbs {
    padding-bottom: 15px;

    a, span:not(:first-of-type) {
      font-family: $sans-serif-bold;
    }

    a, span {
      @extend p.body-mediumSmall;
      @extend p.body\@m-small;
    }

    span {
      @extend .date;

      &:first-of-type {
        font-family: $sans-serif-book;
      }

      &:not(:first-of-type) {
        margin: 0 5px;
        color: map($color-green, base);
      }
    }
  }

  #{ $self }__share {
    @extend .mr\@m-5;
    position: absolute;
    right: 0;
    bottom: 0;

    @include grid-media($grid-sm) {
      top: 0;
    }

    a {
      @extend .border;
      @extend .border\@m-b;
      @extend .border-b-rs;
      float: left;
      padding: 0 8px;

      &.icon-print {
        @extend .hide\@s;
        @extend .icon-print-small;
      }

      &.icon-share {
        @extend .border\@s-rs;
        @extend .icon-share-small;
        @extend .border-l-rs;
        line-height: 2;
      }
    }
  }

  #{ $self }__share-buttons {
    float: right;
    opacity: 0;
    transition: opacity .5s ease;
  }

  &--spotlight {
    background: map($color-blue, ultraLight);

    .spotlight__title {
      @include grid-media($grid-md) {
        font-size: 2em;
        margin: 0.5em 0;
      }
    }

    .spotlight__subtitle {
      font-style: italic;
      line-height: 1.5;
    }

    .col.related-image {
      width: calc(25%);
    }
    .col.related-image-caption {
      width: calc(75%);
    }

    .spotlight__related-title {
      line-height: 1.25;
    }
  }

  &--resources {
    background: map($color-blue, ultraLight);

    .resources__title {
      @include grid-media($grid-md) {
        font-size: 2em;
        margin: 0.5em 0;
      }
    }

    .resources__subtitle {
      font-style: italic;
      line-height: 1.5;
    }

    .col.related-image {
      width: calc(25%);
    }
    .col.related-image-caption {
      width: calc(75%);
    }

    .resources__related-title {
      line-height: 1.25;
    }
  }

  #{ $self }__body, #{ $self }__summary {

    h4 {
      @extend h4.small;
      @extend h4\@m-extraSmall;
      color: map($color-blue, base);
      margin: 60px 0;
    }

    h6 {
      color: map($color-blue, dark);
    }

    h6 + p {
      margin: 0 0 30px 0;
    }

    p {
      font-family: $sans-serif-book;

      a {
        color: map($color-blue, base);
      }
    }

    img {
      height: auto;
      @extend .w\@sm-100;
      max-width: 100%;
      margin-bottom: 2.5em;

      &.align-center {
        text-align: center;
      }

      &.align-left {
        @extend .f\@m-rs;
        @extend .mr\@m-rs;
        margin-right: 30px;
        margin-bottom: 30px;
      }

      &.align-right {
        @extend .f\@m-rs;
        @extend .ml\@m-rs;
        margin-left: 30px;
        margin-bottom: 30px;
      }
    }

    figure {
      @extend .w\@m-100;
      margin-bottom: 2.5em;

      &.align-center {
        display: table;

        figcaption {
          text-align: center;
        }
      }

      &.align-left {
        margin: 0 30px 30px 0;
      }

      &.align-right {
        margin: 0 0 30px 30px;
      }

      &.fl, &.fr {
        @extend .f\@m-rs;
        @extend .m\@m-rs;
      }

      img {
        @extend .image-responsive;
        margin-bottom: 0;
      }

      figcaption {
        display: table-caption;
        caption-side: bottom;
        padding: 20px 40px;
        background: map($color-blue, mediumDark);

        @include grid-media($grid-md) {
          padding: 20px 5%;
          line-height: map($line-height-p, base);
        }
      }
    }

    iframe {
      max-width: 100%;
    }
  }

  #{ $self }__note {

    p {
      margin: 0;
    }
  }

  #{ $self }__author {

    &-image {
      float: left;
      width: 25%;

      img {
        padding-right: 20px;
      }
    }

    &-bio {
      float: left;
      width: 75%;
    }
  }

  #{ $self }__news-list {

    padding: 10px;
    display: flex;
    align-items: center;

    &-image {
      float: left;
      width: 10%;

      img {
        border: 1px solid #9e9e9e;
        padding: 1px;
      }

      @media (max-width: 36em){
        width: 30% !important;
      }
      @media (max-width: 48em){
        width: 20% !important;
      }
    }

    &-summary {
      float: left;
      width: 90%;

      &-label {
        font-family: $serif-bold;
        color: map($color-blue, base);
        padding-bottom: 10px;
        text-decoration: none;
      }

      span {
        color: map($color-green, base);
        font-size: map($font-size-a, small);
        float: right;

        @media (max-width: 52em) {
          width:100%;
        }
      }
    }
  }



  // Modifiers
  &--featured {

    #{ $self }__content {
      @extend .border-t;
      @extend .border-b;
      padding: 60px 0;

      #{ $self }__body {

        &.has-dropcaps {

          p {

            &::first-letter {
              @extend .dropcaps;
            }
          }
        }

        @include grid-media($grid-md) {
          padding: 5% 5%;
        }
      }

      @include grid-media($grid-md) {
        padding: 0 0 30px 0;
      }
    }

    #{ $self }__image {
      float: left;
      padding: 0 15px 0 0;

      figcaption {
        padding: 8px;
        background-color: map($color-blue, base);
        font-size: rem(map($font-size-p, extraSmall));
        line-height: map($line-height-p, extraSmall);
      }
    }
  }

  &--info {
    @extend .border-b;
    padding: 60px 0;

    @include grid-media($grid-md) {
      padding: 0;
      margin: 30px 0;
    }

    &:hover {

      #{ $self }__image {

        img {
          border-color: map($color-green, base);
        }
      }

      #{ $self }__title {

        strong {
          color: map($color-green, base);
        }
      }

      .hover-break {
        strong {
          color: map($color-blue, base) !important;
        }

        img {
          border: solid 8px transparent !important;
        }
      }
    }

    &:last-child {
      border: none;
    }

    #{ $self }__image {
      @extend .p\@m-rs;
      padding: 0 30px 0 0;

      img {
        border: solid 8px transparent;
        transition: border-color .3s ease;
      }
    }

    #{ $self }__content {
      @extend .border-l;
      @extend .border\@m-rs;
      @extend .ph\@m-5;
      padding: 0 0 0 30px;

      p {
        @extend .body\@m-mediumSmall;
      }

      @include grid-media($grid-md) {
        margin: 30px 0;

        p {
          margin: 10px 0;
        }
      }
    }

    #{ $self }__title {
      @extend h2\@m-small;

      strong {
        color: map($color-blue, base);
        transition: color .3s ease;
      }
    }
  }

  &--news, &--related {
    @include grid-container;
    @extend .border-b;
    @extend .mh\@m-5;
    padding: 80px 0;

    #{ $self }__title {
      @extend h3\@m-small;
      @extend h3\@s-extraSmall;
    }

    #{ $self }__summary {
      @extend .hide\@s;
    }

    @include grid-media($grid-md) {
      padding: 20px 0;
    }

    &:last-of-type {
      @extend .border\@m-b-rs;
    }

    &:hover {

      #{ $self }__image {

        img {
          border-color: map($color-green, base);
        }
      }

      #{ $self }__content {

        a {
          color: map($color-blue, base);
        }
      }
    }

    #{ $self }__title {
      @extend .h3;
      @extend .title;
    }

    #{ $self }__image {

      img {
        @extend .image-responsive;
        border: solid 8px transparent;
        transition: border-color .3s ease;
      }
    }

    #{ $self }__content {
      padding: 0 20px;

      a {
        transition: color .3s ease;

        &::after {
          top: -4px;
          left: 20px;
          font-size: 28px;
        }
      }

      p {
        @extend .body;
        @extend .body-mediumLarge;
      }
    }

    #{ $self }__date {
      @extend .date;
      @extend .body;
      @extend .body-medium;
      @extend .body\@s-small;
      margin-top: 20px;

      @include grid-media($grid-md) {
        margin-top: 10px;
      }
    }

    #{ $self }__summary {
      @extend .body;
      @extend .body-large;
      @extend .body\@m-medium;
      margin-top: 40px;
    }
  }

  &--related {
    padding: 40px 0;

    @include grid-media($grid-md) {
      padding: 20px 0;
    }

    hr {
      @extend .hide\@s;
    }

    #{ $self }__date {
      margin-top: 0;
    }

    #{ $self }__summary {
      @extend .body;
      @extend .body-medium;
      margin-top: 0;
    }

    #{ $self }__content {

      a {
        color: map($color-blue, base);
      }
    }

    &:hover {

      #{ $self }__content {

        a {
          color: map($color-green, base);
        }
      }
    }
  }

  &--story {

    #{ $self }__breadcrumbs {

      span {

        &:first-of-type {
          @extend .hide\@s;
        }
      }
    }

    #{ $self }__info {
      position: relative;
      margin-bottom: 15px;
      @extend .ph\@m-5;

      h6 {
        @extend h6\@m-extraSmall;
      }
    }

    h1.article__title{
      font-size: rem(map($font-size-h1, \@m));
    }

    h2.article__title{
      font-style: italic;
      color: map($color-blue, base);
      font-size: rem(map($font-size-h4, extraSmall));
      font-family: $serif-medium;
      line-height: map($line-height-h4, extraSmall);
    }

    #{ $self }__title {
      @extend .title;
      @extend h2\@m-small;
      @extend .mh\@m-5;

      @include grid-media($grid-md) {
        margin-top: 30px;
        font-size: rem(map($font-size-h4, extraSmall));
      }
    }

    #{ $self }__body {
      margin-top: 50px;

      ul, ol {
        font-size: rem(map($font-size-p, mediumLarge));
        line-height: map($line-height-p, medium);
        font-family: $sans-serif-book;
        padding-left: 1rem;
        margin: 0 0 0 3em;

        @media (max-width: 64em) {
          margin: 0 1em;
          padding-left: 1.5rem;
          font-size: rem(map($font-size-p, base));
          line-height: map($line-height-p, medium);
        }
      }

      ul {
        list-style-type: disc;
      }


      iframe {

        @include grid-media($grid-sm) {
          height: 300px;
        }

        @include grid-media($grid-xs) {
          height: 200px;
        }
      }

      p {
        @extend .body;
        @extend .body-mediumLarge;
        @extend .body\@m-base;
        margin: 30px 0;
      }

      blockquote {
        @extend .border\@m-rs;
        padding-right: 150px;
        padding-left: 50px;
        border-left: solid 20px map($color-blue, base);

        p {
          @extend .blockquote;
          @extend blockquote\@m-small;
          position: relative;
          font-family: $serif-mediumItalic;
          color: map($color-blue, base);

          &::before {
            content: open-quote;
            position: absolute;
            left: -20px;
          }

          &::after {
            content: close-quote;
          }
        }

        @include grid-media($grid-md) {
          padding-right: 5%;
          padding-left: 5%;
        }

        @include grid-media($grid-sm) {
          &::before {
            left: 12px;
          }
        }

        @include grid-media($grid-xs) {
          &::before {
            left: 6px;
          }
        }
      }

      @include grid-media($grid-md) {
        margin-top: 30px;

        h1, h2, h3, h4, h5, h6, p {
          margin: 30px 5%;
        }
      }
    }

    #{ $self }__footer {
      @extend .mh\@m-5;
    }

    #{ $self }__note {

      p {
        margin: 0;
        @extend .body;
        @extend .body-mediumLarge;
      }
    }

    #{ $self }__author {

      @include grid-media($grid-md) {
        margin-top: 30px;

        &-image {
          width: 15%;
        }

        &-bio {
          width: 85%;
        }
      }

      @include grid-media($grid-sm) {

        &-image {
          width: 25%;
        }

        &-bio {
          width: 75%;
        }
      }

      @include grid-media($grid-xs) {

        &-image {
          width: 35%;
        }

        &-bio {
          width: 65%;
        }
      }

      p {
        @extend p.summary;
        margin: 0;
        font-size: rem(map($font-size-p, small));
        line-height: map($line-height-p, small);

        strong {
          @extend p.title;
        }
      }
    }
  }

  &--bio {
    a {
      font-family: MalloryBook, sans-serif !important;
    }

    #{ $self }__info {
      position: relative;
      margin-bottom: 40px;
      @extend .ph\@m-5;
    }

    #{ $self }__breadcrumbs {

      span {
        font-family: $serif-bold;
        color: map($color-green, base);
      }

      a {
        margin: 0 5px;
        color: map($color-blue, dark);
      }
    }

    #{ $self }__header {
      @extend .ph\@m-5;
      @extend .mb-5;
      @extend .mb\@s-rs;
    }

    #{ $self }__image {

      img {
        @extend .pr\@m-5;
        @extend .pr\@s-rs;
      }
    }

    #{ $self }__author {

      &-name, &-role {

        @include grid-media($grid-md) {
          margin: 30px 0;
        }
      }

      &-name {
        @extend .title;
        @extend h2\@m-small;
      }

      &-role {
        margin: 40px 0;
        @extend .body;
        @extend .body-mediumLarge;
        @extend .body\@m-base;
      }

      &-contact {
        @extend .border\@s-t;
      }

      &-address, &-hours, &-cv {

        @include grid-media($grid-sm) {
          padding: 15px 0;
        }

        a {
          font-weight: bold;
        }
      }

      &-address {
        a::after {
          content: none;
        }
      }

      &-hours {
        @extend .border-r;
        @extend .border\@s-r-rs;
        @extend .border\@s-t;
        @extend .pl\@m-rs;
      }

      &-cv {
        @extend .border\@s-t;
        @extend .pl\@s-rs;

        i {
          color: #C04829;
          padding-right: .5em;
        }
      }
    }

    #{ $self }__website {
      @extend .border-t;
      padding: 1em 10px;

      span {
        padding: 0 .75em;
      }

      a {
        text-decoration: none;
        display: inline-block;
        font-weight: bold;
      }

      a::after {
        content: none;
      }

      @include grid-media($grid-md) {
        margin: 0 5%;
        padding: 10px;

        a {
          display: block;
          line-height: 1.5;
          padding-bottom: .5em;
        }
        span {
          display: none;
        }
      }
    }

    #{ $self }__body, #{ $self }__summary {
      @extend .border-t;
      padding: 30px 0 0 0;

      h6 {
        @extend .extraSmall;
      }

      p {
        @extend .body;
        @extend .body-mediumLarge;
        @extend .body\@m-base;
        margin: 30px 0;
      }

      hr {

        & + h6 {
          margin: 30px 0 0 0;
        }
      }

      @include grid-media($grid-md) {
        margin: 0 5%;
      }
    }

    #{ $self }__fundopd {
      padding: 30px 0 0 0;

      h6 {
        @extend .extraSmall;
      }

      p {
        @extend .body;
        @extend .body-mediumLarge;
        @extend .body\@m-base;
        margin: 30px 0;
      }

      hr {

        & + h6 {
          margin: 30px 0 0 0;
        }
      }

      strong {
        color: #555;
      }



      @include grid-media($grid-md) {
        margin: 0 5%;
      }
    }

    #{ $self }__funding {
      @include grid-media($grid-md) {
        margin: 0;
        padding-left: 0;

        p {
          margin: 0;
        }
      }
    }

    #{ $self }__summary {
      margin: 40px 0 0 0;

      @include grid-media($grid-md) {
        margin: 0 5%;
      }
    }

    #{ $self }__body {

      ul, ol {
        font-size: 1.1rem;
        line-height: inherit;
        font-family: CopernicusBook, serif;
        padding-left: 1rem;
        margin: 0 0 0 3em;

        a {
          color: map($color-blue, base);
          display: inline-block;
          font-size: inherit;
          line-height: inherit;
        }

        @media (max-width: 64em) {
          margin: 0 1em;
          padding-left: 1.5rem;
          font-size: 1rem;
        }
      }

      ul {
        list-style-type: disc;
      }
    }
  }
  &--event {
    @extend .tac\@m;
    display: block;
    padding: 20px 0;

    &:hover {

      #{ $self }__title {
        color: map($color-green, base);
      }
    }

    #{ $self }__date {
      @extend .date;
      @extend .body\@m-small;
    }

    #{ $self }__title {
      @extend h5.title;
      @extend h5.strong;
      @extend .link;
      @extend h5\@m-small;
      transition: color .3s ease;

      @include grid-media($grid-md) {
        margin: 10px 0;
      }
    }

    #{ $self }__time {
      @extend .body;
      @extend .body.strong;
      @extend .body-medium;
      @extend .title;
      @extend .body\@m-mediumSmall;
    }
  }

  &__urbanedge {
    &-info {
      position: relative;
      margin-bottom: 15px;
      border-bottom: solid 1px map($color-blue, extraLight);
      padding-top: 1em;

      @media (max-width: 64em){
        .title {
          padding: 0 1em;
        }
        .tags {
          display: block;
          float: none;
          padding: 0;
        }
      }

      &-author {
        margin-bottom: 1.5em;

        @media (max-width: 64em){
          margin: 0 1em;
        }
        .small {
          font-family: $sans-serif-book;
        }
      }
    }

    &-event {
      background-color: map($color-gray, ultraLight);
      border-top: #D8D8D8 solid 1px;
      border-bottom: #D8D8D8 solid 1px;
      padding: 1em;
      display: flex;
      flex-wrap: wrap;
      font-family: $sans-serif-book;
      color: map($color-gray, lockup);

      a {
        color: map($color-blue, base);
      }

      .col {
        flex: 1 1 250px;
      }

      .col-4 {
        margin:auto;
        text-align: center;
      }
    }

    &-file-link{
      a {
        width: 100%;
      }
    }

    &-document{
      padding: .5em 1em;
      border-bottom: map($color-gray, extraLight) solid 2px;
      border-top: map($color-gray, extraLight) solid 2px;
      background-color: map($color-gray, ultraLight);
      margin: 1em 0;
      display: flex;

      @media (max-width: 36em){
        flex-wrap: wrap;
        padding-right: 0;
        padding-left: 0;
      }

      .left {
        border-right: map($color-blue, extraLight) solid 1px;
        padding-right: 1em;
        margin: auto;

        @media (max-width: 36em){
          border-right: none;
        }
      }

      img {
        max-width: 120px;
        max-height: 155px
      }

      .right {
        padding: 0 1em;
        flex-grow: 2;

        @media (max-width: 36em){
          padding: 0;
        }

        h6 {
          color: map($color-blue, medium);
          font-weight: bold;

          @media (max-width: 36em){
            line-height: 2;
          }
        }

        .date {
          color: map($color-blue, base);
        }

        .arrow {
          transform: translate(-10px,-2px) rotate(45deg);
          border: solid map($color-blue, base);
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;

          &::after{
            display: none;
          }
        }
      }

    }

    &-sponsors {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));

      @media (max-width: 64em){
          margin: 1em;
      }
    }

    &-sponsor {
      padding: 0 .25em;

      .img-container{
        height: 160px;
        border: map($color-gray, base) solid 1px;
        padding: 1em;
        position: relative;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          position: relative;
          top: 50%;
          left: 50%;
          margin-right: -50%;
          transform: translate(-50%, -50%)}
      }

    }

    &-news-ref {
      border-bottom: solid 1px map($color-blue, sharpLight);
      padding: .5em;
    }

    &-news {
      font-family: $sans-serif-book;
      border-bottom: none;

      .article__content .article__title {
        font-size: 1.5rem;
      }

      p.article__summary {
        font-size: 1rem;

        @media (max-width: 35em){
          display: block!important;
        }
      }

      @media (max-width: 35em){
         .col-3, .col-9 {
           width:calc(100%);
         }
         .col-3 {
           padding: 0 3em;
         }
      }
    }

    &-support-materials {
      margin: 2em 0;
    }

    &-related-initiatives {
      .col-6 {
        width: calc(100%);
      }
    }

    &-contributor {
      .article__author {
        display: flex;
        margin-bottom: 1em;
        padding-bottom: 1em;
        border-bottom: map($color-gray, extraLight) solid 1px;

        &-image {
          width: auto;
        }
      }

      .author-details a {
        margin:1em 2em 0 0;
        color: map($color-blue, base);
      }

      .col-1 {
        width: 10%;
      }
      .col-10 {
        width: 90%;
      }

      @media (max-width: 64em){
        .col-1 { width: 20%; }
        .col-10 { width: 80%; }
      }
    }

    @media (max-width: 64em) {
      &-support-materials, &-file-link, &-inthenews, &-related-initiatives {
        margin: 1em;
      }
    }
  }
}
