.icon {
  background-repeat: no-repeat;
  background-position: center;

  // Modifiers
  &-close {
    top: 60% !important;
    &::before {
      content: "\f00d";
      font: normal normal normal 14px/1 Font Awesome\ 6 pro;
      font-size: 2.75em;
      color: map($color-gray, light);
      margin-top: 10px;
    }
  }

  &-print {
    &::before {
      content: "\f02f";
      font: normal normal normal 14px/1 Font Awesome\ 6 pro;
      font-weight: 900;
      font-size: 1em;
      color: map($color-gray, dark);
    }

    &-small {
      &::before {
        font-size: .75em;
      }
    }
  }

  &-social {
    line-height: 2;
    top: 54%;
    left: 48%;

    &::before {
      content: "\f1e0";
      font: normal normal normal 14px/1 Font Awesome\ 6 pro;
      font-size: 1.25em;
      font-weight: 900;
      color: map($color-white, base);
    }

    &-small {
      &::before {
        font-size: .75em;
      }
    }
  }

  &-share {
    line-height: 1;

    &::before {
      content: "\f1e0";
      font: normal normal normal 14px/1 Font Awesome\ 6 pro;
      font-size: 1.25em;
      font-weight: 900;
      color: map($color-green, base);
    }

    &-small {
      &::before {
        font-size: .75em;
      }
    }
  }

  &-search {
    top: 54%;

    &::before {
      content: "\f002";
      font: normal normal normal 16px/1 Font Awesome\ 6 Pro;
      font-size: 1.4em;
      font-weight: 600;
      color: map($color-green, base);
    }
  }

  &-filter {
    top: 10%;

    &::before {
      content: "\f0b0";
      font: normal normal normal 14px/1 Font Awesome\ 6 Pro;
      font-size: 1.25em;
      font-weight: 900;
      color: map($color-gray, light);
      //padding: 0 .5em 0 2em;
    }
  }

  &-linkdin {
    &::before {
      content: "\f08c";
      font: normal normal normal 14px/1 Font Awesome\ 6 Brands;
      font-size: 1.6em;
      color: map($color-green, base);
    }
  }

  &-fb {
    &::before {
      content: "\f09a";
      font: normal normal normal 14px/1 Font Awesome\ 6 Brands;
      font-size: 1.6em;
      color: map($color-green, base);
    }
  }

  &-twitter {
    &::before {
      content: "\e61b";
      font: normal normal normal 14px/1 Font Awesome\ 6 Brands;
      font-size: 1.4em;
      color: map($color-green, base);
    }
  }

  &-email {
    &::before {
      content: "\f0e0";
      font: normal normal normal 14px/1 Font Awesome\ 6 Pro;
      font-size: 1.6em;
      color: map($color-green, base);
    }
  }

  &-google {
    &::before {
      content: "\f0d4";
      font: normal normal normal 14px/1 Font Awesome\ 6 Brands;
      font-size: 1.6em;
      color: map($color-green, base);
    }
  }


  &-yt {
    &::before {
      content: "\f167";
      font: normal normal normal 14px/1 Font Awesome\ 6 Brands;
      font-size: 1.6em;
      color: map($color-blue, extraLight);
    }
  }

  &-instagram {
    &::before {
      content: "\f16d";
      font: normal normal normal 14px/1 Font Awesome\ 6 Brands;
      font-size: 1.6em;
      color: map($color-blue, extraLight);
    }
  }

  &-login {
    &::before {
      content: "\f007";
      font: normal normal normal 14px/1 Font Awesome\ 6 pro;
      font-size: 1.5em;
      font-weight: 900;
      color: map($color-gray, light);

      @include grid-media($grid-sm) {
        font-size: 2em;
        margin-right: 45px;
      }
    }
  }

  &-menu {
    top: 60% !important;
    &::before {
      content: "\f0c9";
      font: normal normal normal 14px/1 Font Awesome\ 6 pro;
      font-size: 2em;
      color: map($color-gray, light);
      margin-top: 10px;
    }

    @include grid-media($grid-md) {
      padding-top: 5px
    }
  }


  &-map {
    width: 60px;
    height: 80px;
    background-image: url('https://staticws.b-cdn.net/assets/img/icons/map_icon.png');

    @include grid-media($grid-md) {
      width: 20px;
      height: 30px;
      background-size: contain;
    }
  }

  &-play {
    &::before {
      position: absolute;
      content: "\f04b";
      font: normal normal normal 14px/1 Font Awesome\ 6 pro;
      font-size: 2.25em;
      font-weight: 900;
      color: map($color-green, base);
      top: 50%;
      left: 55%;
      transform: translate(-50%, -50%);
    }
  }

  &-plus {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: solid 1px map($color-blue, base);

    &::after, &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transform-origin: 0 0;
      width: 1px;
      height: 16px;
      background-color: map($color-blue, base);
    }

    &::after {
      transform: rotate(90deg) translate(-50%, -50%);
    }
  }

  &-arrow {
    &-down {
      position: relative;
      &::after {
        content: "\f078";
        font: normal normal normal 14px/1 Font Awesome\ 6 Pro;
        font-size: 1em;
        color: map($color-green, base);
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &-up {
      &::after {
        content: "\f077";
        font: normal normal normal 14px/1 Font Awesome\ 6 Pro;
        font-size: 2.5em;
        color: map($color-blue, base);
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &-right {
      &::after {
        position: relative;
        content: "\f054";
        font: normal normal normal 14px/1 Font Awesome\ 6 Pro;
        font-size: 1em;
        margin-left: 10px;
        color: map($color-green, base);
      }
    }
  }

  &-spinner {

    &::after {
      content: "\f3f4";
      font: normal normal normal 14px/1 Font Awesome\ 6 pro;
      color: map($color-green, base);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @extend .animate-spin;
    }
  }
}
