.lockup {
  $self: &;
  @extend .inline-block;
  @extend .flex\@m;
  @extend .mh-auto;
  @extend .tac;

  > a {
    vertical-align: top;
  }

  #{ $self }__image {
    @extend .block;
    @extend .image-max;
    vertical-align: top;

    @include grid-media($grid-md) {
      align-self: flex-start;
      max-width: 34px;
    }
  }

  #{ $self }__text {
    @extend .inline-block;
    margin-top: 5px;
    margin-left: 16px;

    a {
      @extend .block;
    }

    @include grid-media($grid-md) {
      margin-top: 3px;
      margin-left: 10px;
    }
  }

  #{ $self }__parent {
    @extend .tal;
    font-family: $serif-title;
    font-size: rem(20px);
    font-weight: 500;
    line-height: 1;
    color: map($color-blue, lockup);
    text-transform: uppercase;

    @include grid-media($grid-md) {
      font-size: rem(12px);
    }
  }

  #{ $self }__child1 {
    @extend .tal;
    font-family: $serif-book;
    font-size: rem(30px);
    line-height: 1.25;
    color: map($color-gray, lockup);
    white-space: pre-line;

    @include grid-media($grid-md) {
      font-size: rem(18px);
    }
  }

  #{ $self }__child2 {
    @extend .tal;
    font-family: $serif-bookItalic;
    font-size: rem(16px);
    line-height: 1.25;
    color: map($color-gray, lockup);
    white-space: pre-line;

    @include grid-media($grid-md) {
      font-size: rem(12px);
    }
  }

  #{ $self }__caption {
    @extend p.caption;
    clear: both;
    max-width: 60%;
    margin: 30px auto 0 auto;
    color: map($color-blue, dark);

    @include grid-media($grid-md) {
      max-width: 100%;
      margin: 30px 5% 0 5%;
    }
  }
}
