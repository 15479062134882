/*
==============
Headings
==============
*/
.h1, .h2, .h3, .h4, .h5, .h6 {

  &.strong {
    font-family: $serif-medium;
  }
}

.h1 {
  @extend h1;
}

.h2 {
  @extend h2;
}

.h3 {
  @extend h3;
}

.h4 {
  @extend h4;
}

.h5 {
  @extend h5;
}

.h6 {
  @extend h6;
}

h1 {

  &.title {
    color: map($color-blue, superDark);

    strong {
      color: map($color-blue, base);
    }
  }

  &.small {
    
    &\@m {
      @include grid-media($grid-md) {
        font-size: rem(map($font-size-h1, \@s)) !important;
        line-height: map($line-height-h1, \@s) !important;
      }
    }
  }

  &\@m {
    @include grid-media($grid-md) {
      &-medium {
        font-size: rem(map($font-size-h1, \@m));
        line-height: map($line-height-h1, \@m);
      }
    }
  }

  &[class*='border'] {
    padding-bottom: 15px;
  }
}

h2 {
  
  &.title {
    color: map($color-blue, dark);

    strong {
      color: map($color-blue, base);
    }
  }

  &.large {
    font-size: rem(map($font-size-h2, large));
    line-height: map($line-height-h2, large);
  }

  &\@m {
     @include grid-media($grid-md) {
       &-small {
         font-size: rem(map($font-size-h2, \@s));
         line-height: map($line-height-h2, \@s);
       }
     }
   }
}

h3 {

  &.title {
    color: map($color-blue, superDark);
  }

  &.mediumSmall {
    font-size: rem(map($font-size-h3, mediumSmall));
    line-height: map($line-height-h3, mediumSmall);
  }

  &\@m {
    @include grid-media($grid-md) {
      &-small {
        font-size: rem(map($font-size-h3, small));
        line-height: map($line-height-h3, small);
      }
    }
  }

  &\@s {
    @include grid-media($grid-sm) {
      &-extraSmall {
        font-size: rem(map($font-size-h3, extraSmall));
        line-height: map($line-height-h3, extraSmall);
      }
    }
  }
}

h4 {

  &.title {
    font-family: $serif-medium;
    color: map($color-blue, base);
  }

  &.wide {
    font-family: $serif-medium;
    letter-spacing: 10px;
    color: map($color-white, base);
  }

  &.small {
    font-size: rem(map($font-size-h4, small));
    line-height: map($line-height-h4, small);
  }

  &.medium {
    font-size: rem(map($font-size-h4, medium));
    line-height: map($line-height-h4, medium);
  }

  &.mediumSmall {
    font-size: rem(map($font-size-h4, mediumSmall));
    line-height: map($line-height-h4, mediumSmall);
  }

  &.extraSmall {
    font-size: rem(map($font-size-h4, extraSmall));
    line-height: map($line-height-h4, extraSmall);
  }

  &\@m {
    @include grid-media($grid-md) {
      &-extraSmall {
        font-size: rem(map($font-size-h4, extraSmall));
        line-height: map($line-height-h4, extraSmall);
      }
    }
  }
}

h5 {

  &.title {
    color: map($color-blue, base);
  }

  &.strong {
    font-family: $serif-medium;
  }

  &.medium {
    font-size: rem(map($font-size-h5, medium));
    line-height: map($line-height-h5, medium);
  }

  &\@l{  
    @include grid-media($grid-lg) {
      &-small {
        font-size: rem(map($font-size-h5, small));
        line-height: map($line-height-h5, small);
      }
    }
  }

  &\@ml {  
    @include grid-media($grid-ml) {
      &-small {
        font-size: rem(map($font-size-h5, \@s));
        line-height: map($line-height-p, base);
      }
    }
  }

  &\@m {  
    @include grid-media($grid-md) {
      &-small {
      font-size: rem(map($font-size-h5, small));
      line-height: map($line-height-h5, small);
      }
    }
  }
}

h6 {

  &.title {
    font-family: $serif-book;
    color: map($color-blue, base);

    strong {
      font-family: $serif-medium;
    }
  }

  &.extraSmall {
    font-size: rem(map($font-size-h6, extraSmall));
    line-height: map($line-height-h6, small);
  }

  &.small {
    font-size: rem(map($font-size-h6, small));
    line-height: map($line-height-h6, small);

    a {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &\@m {
    @include grid-media($grid-md) {
      &-extraSmall {
        font-size: rem(map($font-size-h6, extraSmall));
        line-height: map($line-height-h6, small);
      }
    }
  }
}

header {
  h2 {
    strong {
      color: map($color-blue, base);
    }
  }
}
/*
================================
Paragaph & Anchors
================================
*/
.p {
  @extend p;
}

.a {
  @extend a;
}

p, a {

  &.medium {
    font-family: $serif-book;
    font-size: rem(map($font-size-h5, medium));
    line-height: map($line-height-h5, medium);

    strong {
      font-family: $serif-bold;
      color: map($color-blue, extraLight);
    }
  }

  &.small {
    font-family: $sans-serif-book;
    font-size: rem(map($font-size-p, small));
    line-height: map($line-height-p, small);
    color: map($color-gray, base);
  
    strong {
      font-family: $sans-serif-bold;
      color: map($color-blue, base);
    }
  }
}

p {

  &>a {
    text-decoration: underline;
  }

  &.title {
    color: map($color-blue, base);
  }

  &.base {
    font-size: rem(map($font-size-p, base));
    line-height: map($line-height-p, base);

    &-tall {
      font-size: rem(map($font-size-p, base));
      line-height: map($line-height-p, large);
    }
  }

  &.mediumSmall {
    font-size: rem(map($font-size-p, mediumSmall));
    line-height: map($line-height-p, medium);
  }

  &.mediumLarge {
    font-size: rem(map($font-size-p, mediumLarge));
    line-height: map($line-height-p, mediumLarge);
  }

  &.large {
    font-size: rem(map($font-size-p, large));
    line-height: map($line-height-p, large);
  }

  &.extraLarge {
    font-size: rem(map($font-size-p, extraLarge));
    line-height: map($line-height-p, extraLarge);
  }

  &\@ml {

    @include grid-media($grid-ml) {
      &-small {
        font-size: rem(map($font-size-p, small));
        line-height: map($line-height-p, small);
      }
    }
  }

  &\@m {
    
        @include grid-media($grid-md) {
          &-base {
            font-size: rem(map($font-size-p, base));
            line-height: map($line-height-p, base);
          }
          &-mediumSmall {
            font-size: rem(map($font-size-p, mediumSmall));
            line-height: map($line-height-p, base);
          }
        }
      }

  &.body {
    font-family: $serif-book;

    &.strong {
      font-family: $serif-medium;
    }

    &.extraStrong {
      font-family: $serif-bold;
    }

    &-extraSmall {
      font-size: rem(map($font-size-p, small));
      line-height: map($line-height-p, \@xs);
    }
    
    &-medium {
      font-size: rem(map($font-size-p, medium));
      line-height: map($line-height-p, medium);
    }

    &-mediumSmall {
      font-size: rem(map($font-size-p, mediumSmall));
      line-height: map($line-height-p, medium);
    }

    &-mediumLarge {
      font-size: rem(map($font-size-p, mediumLarge));
      line-height: map($line-height-p, mediumLarge);
    }
    
    &-large {
      font-size: rem(map($font-size-p, large));
      line-height: map($line-height-p, large);
    }

    &-mediumExtraLarge {
      font-size: rem(map($font-size-p, large));
      line-height: map($line-height-p, large);

      em strong, strong em {
        font-family: $serif-heavyItalic;
      }
    }

    &-superLarge {
      font-size: rem(map($font-size-p, superLarge));
      line-height: map($line-height-p, superLarge);
    }
    
    &\@m {
      @include grid-media($grid-md) {
        &-small {
          font-size: rem(map($font-size-p, small));
          line-height: map($line-height-p, small);
        }

        &-mediumSmall {
          font-size: rem(map($font-size-p, mediumSmall));
          line-height: map($line-height-p, medium);
        }

        &-medium {
          font-size: rem(map($font-size-p, medium));
          line-height: map($line-height-p, \@m);
        }

        &-base {
          font-size: rem(map($font-size-p, base));
          line-height: map($line-height-p, medium);
        }

        &-mediumLarge {
          font-size: rem(map($font-size-p, mediumLarge));
          line-height: map($line-height-p, mediumLarge);
        }
      }
    }
    
    &\@s {
      @include grid-media($grid-sm) {
        &-small {
          font-size: rem(map($font-size-p, small));
          line-height: map($line-height-p, small);
        }

        &-mediumSmall {
          font-size: rem(map($font-size-p, mediumSmall));
          line-height: map($line-height-p, medium);
        }

        &-medium {
          font-size: rem(map($font-size-p, medium));
          line-height: map($line-height-p, \@m);
        }

        &-base {
          font-size: rem(map($font-size-p, base));
          line-height: map($line-height-p, medium);
        }
      }
    }
  }

  &.caption {
    font-family: $sans-serif-light;
    font-size: rem(map($font-size-p, mediumSmall));
    line-height: map($line-height-p, mediumSmall);
  }

  &.excerpt {
    font-family: $serif-bookItalic;
    font-size: rem(map($font-size-p, base));
    line-height: map($line-height-p, medium);
    color: map($color-blue, base);
  }

  &.summary {
    font-family: $sans-serif-book;
    font-size: rem(map($font-size-p, base));
    line-height: map($line-height-p, base);
  
    &-tall {
      margin: 12px 0;
      line-height: line-height(map-get($font-size-p, base), 36px);
    }
  
    &-extraLarge {
      font-size: rem(map($font-size-p, extraLarge));
      line-height: map($line-height-p, extraLarge);
    }
  }
}

a {

  &.body {
    @extend p.body;

    &-small {
      font-size: rem(map($font-size-a, small));
      line-height: map($line-height-a, small);
    }

    strong {
      font-family: $serif-bold;
    }
  }

  &.h3 {
    color: map($color-blue, dark);
  }

  &.h6 {
    font-family: $serif-medium;
    line-height: 1.5;
    color: map($color-blue, base);
  }

  &.strong {
    font-family: $sans-serif-bold;
  }

  &\@m {
    @include grid-media($grid-md) {
      &-extraSmall {
        font-size: rem(map($font-size-a, extraSmall));
        line-height: map($line-height-a, extraSmall);
      }
    }
  }
}

/*
==============
Blockquote
==============
*/
.blockquote {
  font-size: rem(map($font-size-blockquote, base));
  line-height: map($line-height-blockquote, base);
}

blockquote {

  &\@m {
    @include grid-media($grid-md) {
      &-small {
        font-size: rem(map($font-size-blockquote, small));
        line-height: map($line-height-blockquote, small);
      }
    }
  }
}

/*
==============
Generic
==============
*/
.date {
  color: map($color-gray, medium);
}

ul {
  list-style-type: none;
}

/*
==============
Photos
==============
*/
.photos {
  position: relative;

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -33px;
    width: 100%;
    text-align: center;
    color: map($color-white, base);

    strong {
      color: map($color-blue, mediumLight);
    }
  }
}