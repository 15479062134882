.calendar-group {

  .calendar {

    @include grid-media($grid-md) {
      &:first-of-type {

        .slick-prev,
        .slick-next {
          top: 100% !important;
        }
      }

      &:last-of-type {

        .slick-arrow {
          display: none !important;
        }
      }
    }
  }
}

.calendar-group, .js-articles-slick, .js-calendar-slick, .js-rice-news, .js-current-news, .js-view-current-news {
  width: 100%;

  .slick-prev {
    left: 0;

    &::before {
      @include triangle(16px, map($color-white, base), left);
      position: relative;
      right: 2px;
    }
  }

  .slick-next {
    right: 0;

    &::before {
      @include triangle(16px, map($color-white, base), right);
      position: relative;
      left: 2px;
    }
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    z-index: 999;
    background-color: map($color-blue, base) !important;
    transition: background-color .3s ease;

    span {
      display: none;
    }

    &::before {
      content: '';
      font-size: 0;
    }

    &:hover {
      background-color: map($color-blue, base) !important;;
    }
  }
}

.js-articles-slick .slick-slide > div:not(:last-child), .js-rice-news .slick-slide > div:not(:last-child),
.js-current-news .slick-slide > div:not(:last-child), .js-view-current-news .slick-slide > div:not(:last-child) {
  padding-bottom: 1em;
  margin: 1.25em 0;
  border-bottom: #afd4ee solid 1px;
}
