div.banner {
  
  h1 {
    padding: 120px 0 70px 0;
    background-color: map($color-blue, megaLight);
  }

  p {
    padding: 80px 0;
  }

  @include grid-media($grid-md) {
    
    h1 {
      padding: 60px 5% 30px 5%;
      font-size: rem(map($font-size-h1, \@s)) !important;
      line-height: map($line-height-h1, \@s) !important;
    }

    p {
      padding: 40px 5%;
    }
  }
}