.paragraph {
  $self: &;

  &__background {
    background-color: map($color-gray, superLight);
  }

  &__section {
    padding: 2em 0;

    h1 {
      color: map($color-white, base);
      text-align: center;

      @include grid-media($grid-md) {
        font-size: map($font-size-h1, \@m);
        line-height: 1.25;
      }
      @include grid-media($grid-sm) {
        font-size: map($font-size-h1, \@s);
      }
    }

    p {
      color: map($color-white, base);
      text-align: center;
      margin: 2% 0;
      font-size: 1.4rem;
    }

    .image-link {
      padding: 1em 0;

      a {
        font-size: map($font-size-a, medium);
        line-height: 1;
        color: map($color-blue, superDark);
        font-weight: bold;
      }
    }
  }

  &__image__card-wrapper{
    display: block;
    width: 400px;
    background-color: rgba(224,226,230,0.9);
    position: absolute;
    min-height: 250px;
    left: 85px;
    padding: 50px 50px 50px 50px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);

    @include grid-media($grid-md) {
      padding: 30px;
    }

    @include grid-media($grid-sm) {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    }

    .small-title {
      p {
        font-family: MalloryBook,sans-serif;
        line-height: 2.5;
        margin-bottom: 5px;
        font-size: 1.5rem;
      }
    }
    .card-sub-title {
      p {
        font-family: MalloryBook,sans-serif;
        font-size: 1rem;
        line-height: 2.5;
        margin-bottom: 5px;
      }
    }
    .card-main-content {
      p {
        font-family: MalloryBook,sans-serif;
        font-size: 1rem;
        line-height: 1.5;
        margin-bottom: 5px;
      }
    }
    .card-btn {
      background-color: #001e6b;
      color: white;
      padding: 10px 12px;
      font-family: MalloryBook,sans-serif;
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  &__float-right {
    left: auto;
    right: 85px;

    @include grid-media($grid-sm) {
      width: 85%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
}

.tags {
  color: map($color-gray, medium);
  font-family: $sans-serif-book;

  a {
    font-size: inherit;
  }

  &-issues {
    float: right;
    padding-right: 3em;
  }

  &-center {
    font-size: rem(map($font-size-h6, \@m));
    font-weight: bold;
    color: map($color-blue, base);

  }
}

.section-header {
  border-bottom: solid 2px map($color-gray, extraLight);
  font-weight: bold;
}
