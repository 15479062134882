.plyr {
  line-height: 0 !important;
}

.plyr--video {
  max-height: 750px;
  background: #fff !important;
  color: transparent !important;
}

.plyr__video-wrapper {
  background: #fff !important;
}

.plyr__video-embed {
  @include grid-media($grid-hero-video) {
    height: 100% !important;
    padding-bottom: 0 !important;
  }
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  @include grid-media($grid-hero-video) {
    padding-bottom: 58% !important;
    transform: translateY(0) !important;
  }
}

.plyr__poster {
  @include grid-media($grid-hero-video) {
    width: 100% !important;
    background-size: cover !important;
  }
}

.plyr__captions {
  display: none;
}

player, .fallback {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
}