.pagination {
  $self: &;
  position: relative;
  min-height: 96px;
  padding: 20px 0 40px 0;
  background-color: map($color-blue, medium);
  text-align: center;

  @include grid-media($grid-md) {
    padding: 20px 0 40px 0;
    
    h4 {
      font-size: rem(map($font-size-h4, \@m));
      line-height: map($line-height-h4, \@m);
    }
  }

  // Elements
  &__link {
    display: block;
    @extend .h4;
    @extend .icon-arrow-down;

    color: map($color-white, base);
    
    strong {
      color: map($color-blue, superLight);
    }
  }

  // Modifiers
  &--reverse {
    background-color: transparent;

    #{ $self }__link {

      color: map($color-blue, dark);

      strong {
        color: map($color-blue, base);
      }
    }
  }
}

/* Full screen throbber */
.ajax-progress-fullscreen {
  /* Can't do center:50% middle: 50%, so approximate it for a typical window size. */
  left: 49%; /* LTR */
  position: fixed;
  top: 48.5%;
  z-index: 1000;
  background-color: transparent;
  background-image: none;
  @extend .icon-spinner;
}
[dir="rtl"] .ajax-progress-fullscreen {
  left: auto;
  right: 49%;
}
