.page-body__content, .ckeditor {
  padding: 5% 0 5%;

  h1 {
    color: map($color-blue, medium);
    font-family: $serif-medium;
    @extend h1.small\@m;

    &:first-of-type {
      margin-bottom: 1em;
    }

    strong {
      color: map($color-blue, base);
    }
  }

  h3 {
    color: map($color-blue, base);
    font-family: $serif-bold;
    padding-bottom: .25em;
    font-size: map($font-size-h4, extraSmall);
  }

  h4 {
    color: map($color-blue, base);
    font-family: $serif-medium;
    padding-bottom: .25em;
  }

  h6 {
    font-family: $serif-medium;
    color: map($color-blue, base);
    line-height: 1.75;
  }

  p {
    font-size: map($font-size-p, mediumLarge);
    padding-bottom: 2em;
  }

  a {
    display: inline;
    line-height: inherit;
  }

  blockquote {
    @extend .border\@m-rs;
    padding-right: 150px;
    padding-left: 50px;
    margin-bottom: 50px;
    border-left: solid 20px map($color-blue, base);

    p {
      @extend .blockquote;
      @extend blockquote\@m-small;
      position: relative;
      font-family: $serif-mediumItalic;
      color: map($color-blue, base);
      padding-bottom: 0;

      &::before {
        content: open-quote;
        position: absolute;
        left: -20px;
      }

      &::after {
        content: close-quote;
      }
    }

    @include grid-media($grid-md) {
      padding-right: 5%;
      padding-left: 5%;
    }

    @include grid-media($grid-sm) {
      &::before {
        left: 12px;
      }
    }

    @include grid-media($grid-xs) {
      &::before {
        left: 6px;
      }
    }
  }

  ul, ol {
    padding-bottom: 2.5em;
    margin-left: 3em;

    li {
      font-family: $sans-serif-book;
      font-size: rem(map($font-size-p, base));
      line-height: map($line-height-p, base);
    }

    a {
      text-transform: uppercase;
      color: map($color-blue, medium);
      @extend.icon-arrow-right;

      &:hover {
        color: map($color-green, base);
        text-decoration: underline;
        text-decoration-color: map($color-blue, superDark);
      }
    }
  }

  ul {
    list-style-type: disc;
  }

  img {
    @extend .w\@sm-100;
    max-width: 100%;
    height: auto;
    margin-bottom: 2.5em;

    &.align-center {
      text-align: center;
    }

    &.align-left {
      @extend .f\@m-rs;
      @extend .mr\@m-rs;
      margin-right: 30px;
      margin-bottom: 30px;
    }

    &.align-right {
      @extend .f\@m-rs;
      @extend .ml\@m-rs;
      margin-left: 30px;
      margin-bottom: 30px;
    }
  }

  figure {
    @extend .w\@m-100;
    margin-bottom: 2.5em;

    &.align-center {
      display: table;

      figcaption {
        text-align: center;
      }
    }

    &.align-left {
      margin: 0 30px 30px 0;
    }

    &.align-right {
      margin: 0 0 30px 30px;
    }

    &.fl, &.fr {
      @extend .f\@m-rs;
      @extend .m\@m-rs;
    }

    img {
      @extend .image-responsive;
      margin-bottom: 0;
    }

    figcaption {
      display: table-caption;
      caption-side: bottom;
      padding: 20px 40px;
      background: map($color-blue, mediumDark);

      @include grid-media($grid-md) {
        padding: 20px 5%;
        line-height: map($line-height-p, base);
      }
    }
  }

  hr {
    margin: 2.5em;
  }

  pre {
    font-family: $serif-medium;
    padding-bottom: 2em;
    line-height: 2;
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }

  iframe {
    max-width: 100%;
  }
}

.page-teasers {
  padding-top: 60px;
  @include grid-media($grid-md) {
    padding-top: 0px;
  }
  .article--info {
    border-bottom: none !important;
    padding: 30px 0;

    @include grid-media($grid-md){
      padding: 0;
      margin: 0;
    }
    .article__content {
      border: none !important;
      .teaser-summary {
        p {
          font-family: $serif-book;
          font-size: map($font-size-p, mediumLarge);
          padding-bottom: 1.75em;
          line-height: 1.75;
        }
        p:last-of-type {
          padding-bottom: .75em;
        }
        h3 {
          font-size: map($font-size-h5, medium);
          color: map($color-blue, base);
          padding-bottom: .25em;
        }
      }
      .title-bar {
        border-color: map($color-blue, mediumDark);
        margin: 2em 0;
      }
      .article__nav {
        text-transform: uppercase;
        &.menu--article {
          column-gap: 150px;

          @include grid-media($grid-sm) {
            column-count: 1;
          }
        }
        .menu__item {
          font-family: $sans-serif-book;
          font-size: map($font-size-p, base);
          padding-left: 6px;
          outline-offset: -4px;
        }
      }
    }
  }
}

.three-col-text {
  font-size: map($font-size-p, medium);
  color: map($color-blue, medium);
  .accordion--link-list-primary {
    padding-bottom: 0;
  }
  .col-first {
    padding: 0 40px 0 0;

    @include grid-media($grid-md) {
      padding: 1% 0;
    }
  }
  h5 {
    font-family: $sans-serif-book;
    text-transform: uppercase;
    font-size: map($font-size-p, medium);
  }
  p {
    font-size: map($font-size-p, medium);
  }
  a {
    text-transform: uppercase;
    color: map($color-blue, medium);
  }
  h4 {
    @include grid-media($grid-md) {
      font-size: map($font-size-h4, extraSmall);
    }
  }
}

.paragraph--type--page-teaser-content {
  padding-bottom: 2em;
}

.three-col-contain {
  @include grid-media($grid-lg) {
    padding: 20px 20px;
  }
  @include grid-media($grid-md) {
    padding: 5%;
  }
}

