form {

  &.webform-submission-form {
    @extend .grid-mw--1080;
    @extend .ph\@m-5;
    @extend .pv-5;

    .webform-flexbox {
      @extend .block\@m;
      display: flex;
    }

    .webform-flex--1 {
      flex: 1 0 auto;

      &:not(:last-child) {
        margin-right: 1em;
        @extend .mr\@m-rs;
      }
    }

    .fieldset-wrapper {
      margin: .5em 0;
    }

    .fieldset-legend {
      @extend .block;
      @extend h4;
      @extend h4.title;
      color: map($color-blue, baseMedium);
    }

    .fieldgroup .form-item {
      margin: .5em 0;
    }

    .form-item, .form-select, .form-submit {
      margin: 1em 0;
    }

    .description {
      @extend p;
      @extend p.small;
    }

    fieldset {
      margin: 1.5em 0;
    }

    label {
      @extend .inline-block;
      color: map($color-gray, superDark);

      &.form-required {
        position: relative;

        &:after {
          content: '*';
          position: absolute;
          font-weight: bold;
          color: map($color-red, base);
        }
      }
    }

    select {
      @extend .w\@m-100;
    }

    input, textarea, select {
      padding: 4px;
      border: solid 1px map($color-gray, light);
    }

    input[type='text'], input[type='email'], input[type='tel'], textarea {
      @extend .block;
      width: 100%;
    }

    input[type='checkbox'], input[type='checkbox'] + label, input[type='radio'], input[type='radio'] + label {
      display: inline-block;
    }

    input[type='checkbox'] + label, input[type='radio'] + label {
      margin-left: 8px;
    }

    button {
      padding: 8px 12px;
      color: map($color-white, base);
      background-color: map($color-blue, mediumDark);
      border: none;
      transition: background-color .3s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: lighten(map($color-blue, mediumDark), 15%);
      }
    }
  }

  &.user-login-form {
    @extend .grid-mw--1080;
    @extend .ph\@m-5;
    @extend .pv-5;

    .cas-login-link,
    button[type=submit] {
      padding: 0.5rem 1rem;
      background-color: map($color-blue, mediumDark);
      color: map($color-white, base);
      font-weight: bold;
      margin-bottom: 1em;
      line-height: 2;
      border: none;
      transition: background-color .3s ease-in-out;
      &:hover,
      &:focus {
        background-color: lighten(map($color-blue, mediumDark), 15%);
        cursor: pointer;
      }
    }
    .form-item {
      margin-bottom: 1em;
    }
  }
}
